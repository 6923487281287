import styled from "styled-components";
import { Link } from "react-router-dom";

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #101422;
  border-bottom: #416d51 3px solid;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Fundo = styled.div`
 width: 100%;
 z-index: 21
  height: 71px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  background-color: #041334;
  margin-top: 38px;
  z-index: 20;
`;

export const Logo = styled.img`
  width: 125px;
  height: 36px;
  margin-right: 17px;
`;

export const ConteudoMenu = styled.div`
  display: flex;

  ul {
    display: flex;
    margin: 0;
    border-right: #416d51 1px solid;
    li {
      width: auto;
      height: auto;
      list-style: none;

      a {
        white-space: nowrap;
        word-wrap: normal;
        cursor: pointer;
        width: auto;
        height: 67px;
        float: left;
        margin: 0;
        padding: 0 1vw;
        overflow: visible;
        font-size: 13px;
        line-height: 70px;
        color: #fff;
        border-bottom: #101422 3px solid;
        transition: 200ms all ease-in;
        :hover {
          color: #19c455;
          border-bottom-color: #19c455;
        }

        @media (max-width: 1190px) {
          padding: 0 10px;
          font-size: 12px;
        }
      }
    }
  }
`;

export const MenuBotao = styled(Link)`
  display: flex;
  flex-direction: row;
  padding: 0 26px;
  border-right: #416d54 1px solid;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  text-decoration: none;
  transition: 200ms all ease-in;
  border-bottom: #101422 3px solid;

  @media (max-width: 1190px) {
    padding: 0 10px;
    font-size: 12px;
  }
  p {
    color: white;
    text-transform: uppercase;
    margin: 0 10px;
  }
  :hover {
    border-bottom-color: #19c455;
    p {
      color: #19c455;
    }
    svg {
      fill: #19c455;
    }
  }

  p,
  svg {
    transition: 200ms all ease-in;
  }
`;

export const Linkacesso = styled.a`
  display: flex;
  flex-direction: row;
  padding: 0 26px;
  border-right: #416d54 1px solid;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  transition: 200ms all ease-in;
  border-bottom: #101422 3px solid;
  border-top: #101422 3px solid;

  @media (max-width: 1190px) {
    padding: 0 10px;
    font-size: 12px;
  }

  p,
  svg {
    transition: 200ms all ease-in;
  }

  :hover {
    p {
      color: #19c455;
    }
    svg {
      fill: #19c455;
    }

    border-bottom-color: #19c455;
  }

  p {
    margin: 0 10px;
  }
`;
