import dashboard from "../../../images/funcionalidades icones/dashboard.png";
import historico from "../../../images/funcionalidades icones/historico de posições.png";
import relatoriom from "../../../images/funcionalidades icones/relatorios de manutençaõ.png";
import relatorio from "../../../images/funcionalidades icones/relatorios.png";
import status from "../../../images/funcionalidades icones/status de ignicção.png";
import telemetria from "../../../images/funcionalidades icones/telemetria.png";
import tempo from "../../../images/funcionalidades icones/tempo real.png";

export const PointerData = [
  {
    div: (
      <div className="itemPointer">
        <div className="image">
          <img src={dashboard} />
        </div>
        <p> Dashboard </p>
      </div>
    ),

    div2: (
      <div className="itemPointer">
        <div className="image2">
          <img src={dashboard} />
        </div>
        <p> Dashboard</p>
      </div>
    ),
    text: (
      <div className="frasePointer">
        Com relatórios detalhados sobre os <br></br> veículos,distâncias
        abastecimentos<br></br> eventos e muito mais...
      </div>
    ),
  },
  {
    div: (
      <div className="itemPointer">
        <div className="image">
          <img src={historico} />
        </div>
        <p> Relatório de Percurso</p>
      </div>
    ),

    div2: (
      <div className="itemPointer">
        <div className="image2">
          <img src={historico} />
        </div>
        <p> Relatório de Percurso</p>
      </div>
    ),
    text: (
      <div className="frasePointer">
        Com médias calculadas utilizando<br></br> a distância percorrida pelo
        veículo!
      </div>
    ),
  },
  {
    div: (
      <div className="itemPointer">
        <div className="image">
          <img src={relatoriom} />
        </div>

        <p> Relatório de Manutenção</p>
      </div>
    ),

    div2: (
      <div className="itemPointer">
        <div className="image2">
          <img src={relatoriom} />
        </div>

        <p> Relatório de Manutenção</p>
      </div>
    ),
    text: (
      <div className="frasePointer">
        Com detalhamento das informações<br></br> inseridas por motoristas e{" "}
        <br></br> funcionários sobre as<br></br> manutenções realizadas...
      </div>
    ),
  },
  {
    div: (
      <div className="itemPointer">
        <div className="image">
          <img src={relatorio} />
        </div>
        <p>Gestão de Abastecimentos </p>
      </div>
    ),

    div2: (
      <div className="itemPointer">
        <div className="image2">
          <img src={relatorio} />
        </div>
        <p>Gestão de Abastecimentos </p>
      </div>
    ),
    text: (
      <div className="frasePointer">
        Com acesso as localizações posteriores <br></br> dos veículos, com
        endereços<br></br> médiade velocidade e horário!
      </div>
    ),
  },
  {
    div: (
      <div className="itemPointer">
        <div className="image">
          <img src={status} />
        </div>
        <p> Alerta da Ignição</p>
      </div>
    ),

    div2: (
      <div className="itemPointer">
        <div className="image2">
          <img src={status} />
        </div>
        <p> Alerta da Ignição</p>
      </div>
    ),
    text: (
      <div className="frasePointer">
        Com recebimento de push notification<br></br>
        ao acionar a ignição do veículo!
      </div>
    ),
  },
  {
    div: (
      <div className="itemPointer">
        <div className="image">
          <img src={telemetria} />
        </div>
        <p> Telemetria </p>
      </div>
    ),

    div2: (
      <div className="itemPointer">
        <div className="image2">
          <img src={telemetria} />
        </div>
        <p> Telemetria </p>
      </div>
    ),
    text: (
      <div className="frasePointer">
        Com precisão dos dados como <br></br> hodômetro temperatura do baú
        <br></br> e teclado lei motorista...
      </div>
    ),
  },
  {
    div: (
      <div className="itemPointer">
        <div className="image">
          <img src={tempo} />
        </div>

        <p>Rastreamento em Tempo Real</p>
      </div>
    ),

    div2: (
      <div className="itemPointer">
        <div className="image2">
          <img src={tempo} />
        </div>

        <p>Rastreamento em Tempo Real</p>
      </div>
    ),
    text: (
      <div className="frasePointer">
        Com uma marcação no mapa <br></br> mostrando o seu veículo ao vivo
        <br></br> tanto via web, quanto via celular...
      </div>
    ),
  },
];
