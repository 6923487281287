import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import { SiCashapp } from "react-icons/si";

import { Link } from "react-router-dom";

import logo from "../../../images/diversos/logo rfinds empresarial branca.png";
import {
  Fundo,
  BotaoMenuMobile,
  SecoesBotoes,
  SideBarMenu,
  Hamburguer,
  Logo,
  DivHamburguer,
  Menu,
  Maskmenu,
} from "./styles";

const MenuMobile = () => {
  const [open, setOpen] = useState(false);

  const showSiderBar = () => setOpen(!open);

  return (
    <>
      <Menu>
        <DivHamburguer aberto={open} onClick={showSiderBar}>
          <Hamburguer aberto={open}>
            <div>
              <span id="l1" />
              <span id="l2" />
              <span id="l3" />
            </div>
          </Hamburguer>
        </DivHamburguer>
        <SideBarMenu aberto={open}>
          <Link to="/empresa">
            <Logo src={logo} />
          </Link>

          <ul className="nav-menu-items">
            <li className="navbar-toggle">
              <ScrollLink
                to="ComoUsarMobile"
                smooth={true}
                duration={1000}
                onClick={showSiderBar}
              >
                Como usar
              </ScrollLink>
            </li>

            <li className="navbar-toggle">
              <ScrollLink
                to="AvaliacoesMobile"
                smooth={true}
                duration={1000}
                onClick={showSiderBar}
              >
                Avaliações
              </ScrollLink>
            </li>
            <li className="navbar-toggle">
              <ScrollLink
                to="FuncionalidadesMobile"
                smooth={true}
                duration={1000}
                onClick={showSiderBar}
              >
                Funcionalidades
              </ScrollLink>
            </li>
            <li className="navbar-toggle">
              <ScrollLink
                to="AreaDownloadMobile"
                smooth={true}
                duration={1000}
                onClick={showSiderBar}
              >
                Download
              </ScrollLink>
            </li>
            <li className="navbar-toggle">
              <ScrollLink
                to="Formulario"
                smooth={true}
                duration={1000}
                onClick={showSiderBar}
              >
                Fale com a gente
              </ScrollLink>
            </li>
          </ul>
        </SideBarMenu>
      </Menu>

      <Maskmenu aberto={open} onClick={showSiderBar} />

      <Fundo>
        <Link to="/empresa">
          <Logo src={logo} />
        </Link>
        <SecoesBotoes>
          <BotaoMenuMobile>
            <Link
              to="/orcamentoecortedecustos"
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SiCashapp color="#ffe" size="20" />
            </Link>
          </BotaoMenuMobile>
          <a href="http://rcontrol.com.br/rfleet/">
            <FaUser color="#ffe" size="20" />
          </a>
        </SecoesBotoes>
      </Fundo>
    </>
  );
};

export default MenuMobile;
