import styled from "styled-components";

export const Content = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 925px) {
    display: none;
  }

  .lottie-whatsapp {
    position: fixed;
    bottom: 1rem;
    right: 0;
    margin-right: 0.5rem;
    z-index: 200;
    cursor: pointer;
  }
`;

export const ContentSlides = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .content .divTextButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    transition: all 1s;
    color: black;
    text-align: initial;

    h1 {
      font-family: TommyBold;
      letter-spacing: 0.05rem;
      font-size: 1.5rem;
      color: #02291a;
    }
    p {
      margin: 0.2rem;
      font-family: TommyRegular;
      letter-spacing: 0.05rem;
      text-align: justify;
      font-size: 0.9rem;
      color: black;
      width: 80%;
      font-weight: 600;
      font-weight: bold;
    }
    .p1{
      width: 80%;
    }

    .buttons {
      font-family: TommyRegular;
      letter-spacing: 0.05rem;
      display: flex;
      justify-content: center;
    }

    .Button2 {
      background-color: #1f3b7c;
      margin-right: 15px;
    }

    .botaolink {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 0.67rem;
      display: flex;
      align-items: center;
      width: 45%;
      height: 3.3rem;
      margin-top: 0.5rem;
      overflow: visible;
      color: #fff;
      background-color: #041334;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 15px;
      transition: all 0.3s;

      text-decoration: none;
      margin-right: 1rem;
      text-align: center;
    }

    .botaolink2 {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 0.67rem;
      width: 45%;
      display: flex;
      align-items: center;
      height: 3.3rem;
      margin-top: 0.5rem;
      overflow: visible;
      color: #fff;
      background-color: #02291a;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 15px;
      transition: all 0.3s;
      cursor: pointer;

      text-decoration: none;
    }
  }

  .content .divIllustration {
    img {
      height: 20rem;
    }
  }
`;
