import { ConteudoSlides } from "./styles";
import { Link } from "react-router-dom";
import cont1 from "../../../images/iconesAreaVeja/1 - Economia.png";
import cont2 from "../../../images/iconesAreaVeja/2 - Orçamento.png";
import cont3 from "../../../images/iconesAreaVeja/3 - Instalações.png";
import cont4 from "../../../images/iconesAreaVeja/4 - Plataforma.png";
import cont5 from "../../../images/iconesAreaVeja/5 - Tempo Real.png";
import cont6 from "../../../images/iconesAreaVeja/6 - Relatorios.png";
import cont7 from "../../../images/iconesAreaVeja/imagem_ranor4.png";

export const carrosselData = [
  {
    
    div: (
      <ConteudoSlides>
        <img src={cont1} style={{ width: "640px", height: "370px" }}></img>

        <div className="infos">
          <h3>CALCULE SUA ECONOMIA</h3>
          <p>
            Por meio do nosso site, você consegue gerar uma estimativa do valor
            que você pode economizar ao adotar o rastreamento veicular e nossas
            diversas soluções na sua empresa.
          </p>
          <div className="linkbutton">
            <Link
              to="/orcamentoecortedecustos"
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
              target="_blank"
            >
              Calcule agora a economia que você terá
            </Link>
          </div>
        </div>
      </ConteudoSlides>
    ),
  },

  {
    div: (
      <ConteudoSlides>
        <img src={cont2} style={{ width: "640px", height: "370px" }}></img>

        <div id="info1" className="infos">
          <h3>SOLICITE UM ORÇAMENTO</h3>
          <p>
            Preencha o formulário abaixo ou entre em contato conosco para
            receber um orçamento e veja o quão é prático o processo de
            implementação dos rasteadores e de nosso sistema.
          </p>
          <div className="linkbutton">
            <a
              href="http://wa.me/553535219531?text=Ol%C3%A1,%20tudo%20bem?%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento%20com%20a%20RFinds%20-%20%20%20Rastreamentos."
              target="_blank"
              className="botaolink2"
              style={{
                textDecoration: "none",
                color: "white",
                width: "100%",
                height: "100%",
              }}
              target="_blank"
            >
              Solicite já o seu orçamento
            </a>
          </div>
        </div>
      </ConteudoSlides>
    ),
  },
  {
    div: (
      <ConteudoSlides>
        <img src={cont3} style={{ width: "640px", height: "370px" }}></img>

        <div id="info3" className="infos">
          <h3>AGENDE AS INSTALAÇÕES</h3>
          <p>
            Programe uma data ideal para que sua frota receba os nossos
            equipamentos, e logo após essa data, eles estarão disponíveis para
            acompanhamento na nossa plataforma.
          </p>
        </div>
      </ConteudoSlides>
    ),
  },
  {
    div: (
      <ConteudoSlides>
        <img src={cont4} style={{ width: "640px", height: "370px" }}></img>

        <div id="info3" className="infos">
          <h3>ACESSE A PLATAFORMA</h3>
          <p>
            Receba o seu acesso e realize seu login em{" "}
            <a
              href="www.rcontrol.com.br/RFleet"
              style={{
                textDecoration: "none",
              }}
              target="_blank"
            >
              www.rcontrol.com.br/RFleet
            </a>{" "}
            &nbsp; para ver os seus veículos em tempo real e ter acesso a outras
            funções e soluções que ofertamos.
          </p>
        </div>
      </ConteudoSlides>
    ),
  },
  {
    div: (
      <ConteudoSlides>
        <img src={cont5} style={{ width: "640px", height: "370px" }}></img>

        <div id="info3" className="infos">
          <h3>ACOMPANHE EM TEMPO REAL</h3>
          <p>
            No sistema você verá toda a sua frota ou poderá filtrar um veículo
            em específico, tendo opções e detalhes sobre o percurso que os
            veículos estão realizando.
          </p>
        </div>
      </ConteudoSlides>
    ),
  },
  {
    div: (
      <ConteudoSlides>
        <img src={cont6} style={{ width: "640px", height: "370px" }}></img>
        <div id="info3" className="infos">
          <h3>GERE DIVERSOS RELATÓRIOS</h3>
          <p>
            Após explorar as diversas ferramentas, você poderá gerar relatórios
            de percursos, manutenções, abastecimentos, paradas, jornadas,
            eventos, fazer a gestão de viagens, registrar multas, entregas,
            cheklists e muito mais...
          </p>
        </div>
      </ConteudoSlides>
    ),
  },
];
