import styled from "styled-components";
export const Coments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
  justify-content: center;
  background-image: linear-gradient(#ffffff, rgb(243, 242, 240));

  @media (max-width: 925px) {
    display: none;
  }

  .ContentSlideComents {
    width: 100%;
    margin-top: 3rem;
  }
`;

export const FeedBack = styled.div`
  margin: 0 auto;
  margin-bottom: 2rem;
  /* background-color: #1f3b7c; */
  padding-bottom: 1rem;
  height: 100%;
  box-sizing: inherit;
  width: 760px;
  border-radius: 23px 23px 1rem 1rem;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  align-items: center;
  border-radius: 100px;
  display: flex;
  background-color: #e5e5e5;
  border-radius: 20px 20px 100px 100;
  height: 96%;
  flex-direction: column;
  min-height: 250px;

  .infoPerson {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    /* top: 10; bottom: 0;
    left: 0; right: 0;
    margin: auto; */

    /* img {
      margin-top: 2rem;
      height: 145px;
      width: 145px;
    } */
  }

  .text {
    margin: 0;
    padding: 0;
    width: 70%;
    text-align: justify;
    top: 0; bottom: 0;
    left: 0; right: 0;
    margin: auto;
    p {
      font-family: TommyLight;
      padding: 0;
      margin: 02px;
      top: 0; bottom: 0;
      left: 0; right: 0;
      margin: auto;
      height: 125px;
    }
  }
`;
export const TextoAvaliacao = styled.div`
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h3 {
    color: #003823;
    font-family: TommyBold;
    letter-spacing: 0.05rem;
    margin-bottom: 0;
    /* font-weight: bold */
  }
  h4 {
    color: rgb(5 22 15);
    font-family: Tommy;
    letter-spacing: 0.05rem;
    margin-bottom: 0;
    font-size: 18px
  }
  /* :after {
    content: "";
    width: 70px !important;
    height: 2px;
    float: left;
    margin-top: 20px;
    background: #1f3b7c;
    border-radius: 2.5px;
    left: 50%;
  } */
`;
export const TextoDepoimentos = styled.div`
  font-family: TommyRegular;
  letter-spacing: 0.05rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
`;
