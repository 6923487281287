import styled from "styled-components";
import { Link } from "react-router-dom";

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #101422;
  border-bottom: #416d51 3px solid;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Fundo = styled.div`
  max-width: 1240px;
  width: 100%;
  height: 71px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 125px;
  height: 36px;
  margin-right: 17px;
`;

export const ConteudoMenu = styled.div`
  display: flex;
  a {
    cursor: pointer;
    width: auto;
    height: 67px;
    float: left;
    margin: 0;
    padding: 0 1vw;
    overflow: visible;
    font-size: 14px;
    line-height: 70px;
    color: #fff;
    border-bottom: #101422 3px solid;
    transition: 200ms all ease-in;
    :hover {
      color: #19c455;
      border-bottom-color: #19c455;
    }

    @media (max-width: 1190px) {
      padding: 0 10px;
      font-size: 12px;
    }
  }
`;

export const MenuBotao = styled(Link)`
  display: flex;
  flex-direction: row;
  padding: 0 26px;
  border-left: #416d54 1px solid;
  border-right: #416d54 1px solid;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  transition: 200ms all ease-in;
  border-bottom: #101422 3px solid;

  @media (max-width: 1190px) {
    padding: 0 10px;
    font-size: 12px;
  }
  p {
    text-transform: uppercase;
  }

  p,
  svg {
    transition: 200ms all ease-in;
  }

  :hover {
    p {
      color: #19c455;
    }
    svg {
      fill: #19c455;
    }

    border-bottom-color: #007b4b;
  }

  p {
    margin: 0 10px;
  }
`;
export const Linkacesso = styled.a`
  display: flex;
  flex-direction: row;
  padding: 0 26px;
  border-right: #416d54 1px solid;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  transition: 200ms all ease-in;
  border-bottom: #101422 3px solid;

  @media (max-width: 1190px) {
    padding: 0 10px;
    font-size: 12px;
  }
  p,
  svg {
    transition: 200ms all ease-in;
  }

  :hover {
    p {
      color: #19c455;
    }
    svg {
      fill: #19c455;
    }

    border-bottom-color: #007b4b;
  }

  p {
    margin: 0 10px;
  }
`;
