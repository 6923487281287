import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { FaUser } from "react-icons/fa";
import { SiCashapp } from "react-icons/si";

import logo from "../../../images/diversos/logo rfinds empresarial branca.png";
import {
  Content,
  Logo,
  Fundo,
  ConteudoMenu,
  MenuBotao,
  Linkacesso,
} from "./styles";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <Content>
      <Fundo>
        <Link to="/empresa">
          <Logo src={logo} alt="Rfinds Empresarial" />
        </Link>
        <ConteudoMenu>
          <ul>
            <li>
              <ScrollLink to="ComoUsar" smooth={true} duration={1000}>
                Como usar
              </ScrollLink>
            </li>

            <li>
              <ScrollLink to="Avaliacoes" smooth={true} duration={1000}>
                Avaliações
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="Funcionalidades" smooth={true} duration={1000}>
                Funcionalidades
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="AreaDownload" smooth={true} duration={1000}>
                Download
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="Formulario" smooth={true} duration={1000}>
                Fale com a gente
              </ScrollLink>
            </li>
          </ul>
          <MenuBotao>
            <Link
              to="/orcamentoecortedecustos"
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <SiCashapp color="#ffe" size="18" />
              <p>Calcule a redução dos custos</p>
            </Link>
          </MenuBotao>
          <Linkacesso href="http://rcontrol.com.br/rfleet/">
            <FaUser color="#ffe" size="18" />
            <p>Acesso do cliente</p>
          </Linkacesso>
        </ConteudoMenu>
      </Fundo>
    </Content>
  );
};

export default Menu;
