import styled from "styled-components";

export const Content = styled.div`
  height: auto;
  width: 100%;
  background-color: #101422;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  position: relative;
  @media (min-width: 925px) {
    display: none;
  }
`;

export const Mpfooter = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 24.25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 999px;
  width: 100%;

  .box-footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    float: left;
    margin-top: 1rem;
    padding: 0;
    overflow: visible;

    h5 {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
      color: #2be39b;
      font-weight: 700;
      text-align: left;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      text-transform: uppercase;
    }
    ul {
      width: 80%;
      height: auto;
      float: left;
      margin: 15px 0 0 0;
      padding: 0;
      overflow: visible;
      color: white;

      li {
        width: 100%;
        height: auto;
        float: left;
        margin: 0px 0 0 0;
        padding: 4px;
        list-style: none;

        p {
          margin: 3px;
          padding: 0;
          font-size: 1rem;
          line-height: 14px;
          font-weight: 400;
          box-sizing: border-box;

          .telfixo {
            color: #2be39b;
            font-size: 1.1rem;
          }

          span {
            font-size: 0.5rem;
          }
        }
      }
    }
  }
`;

export const Pagamentosfooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: #182333 1px solid;
  border-bottom: #182333 1px solid;
  height: 21rem;
  color: white;
  max-width: 999px;
  width: 100%;
  align-items: center;
  .seguranca {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    figure {
      img {
        margin-left: 1.2rem;
      }
    }
    h5 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1rem;
      text-align: center;
    }
  }
  .redesSociais {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      text-align: center;
    }

    .iconsRedes {
      display: flex;
      a {
        color: white;
        margin: 0 1rem;
        margin-bottom: 1rem;
      }
    }
  }
`;

export const Rodape = styled.div`
  height: 3.5rem;
  color: white;
  display: flex;
  justify-content: center;
  p {
    text-align: center;
    font-size: 0.9rem;
  }
`;
