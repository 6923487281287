import { ConteudoSlides } from "./styles";
import { Link } from "react-router-dom";
import foto1 from "../../../images/funcionalidades icones/1 - DASHBOARD.png";
import foto2 from "../../../images/funcionalidades icones/2 - PERCURSO.png";
import foto3 from "../../../images/funcionalidades icones/3 - GESTAO-DE-ABASTECIMENTO.png";
import foto4 from "../../../images/funcionalidades icones/4 - MANUTENCAO.png";
import foto5 from "../../../images/funcionalidades icones/5 - RASTREAMENTO.png";
import foto6 from "../../../images/funcionalidades icones/6 - IGNNIÇÃO.png";
import foto7 from "../../../images/funcionalidades icones/7 -TELEMETRIA.png";

export const carrosselData = [
  {
    
    div: (
      <ConteudoSlides>
        <div className="imagemcarrin">
          <img src={foto1} alt="Imagem" height="350px" max-width="100%" max-height="100%"></img> 
          <p className="p9">Tenha visibilidade e controle total de sua operação!</p>
          <p className="p8">Relatórios detalhados sobre os veículos, distâncias,<br></br>abastecimentos, eventos e muito mais.</p>
        </div>
      </ConteudoSlides>
    ),
    
  },
  {
    
    div: (
      <ConteudoSlides>
        <div className="imagemcarrin">
          <img src={foto7} alt="Imagem" height="400px" max-width="100%" max-height="100%"></img> 
          <p className="p8">Através desse sistema a gestão de frotas tem controle maior<br></br>
          sobre dados como velocidade média, distância percorrida, consumo<br></br>de
          combustível e temperatura do motor</p>
        </div>
      </ConteudoSlides>
    ),
    
  },
  {
    
    div: (
      <ConteudoSlides>
        <div className="imagemcarrin">
          <img src={foto3} alt="Imagem" height="350px" max-width="100%" max-height="100%"></img> 
          <p className="p8">Saiba o gasto estimado de cada rota de acordo com o tamanho<br></br>
          do veículo, consumo médio, tipo de combustível e valor.</p>
        </div>
      </ConteudoSlides>
    ),
    
  },
  {
    
    div: (
      <ConteudoSlides>
        <div className="imagemcarrin">
          <img src={foto5} alt="Imagem" height="350px" max-width="100%" max-height="100%"></img> 
          <p className="p8">Saiba onde estão seus carros a todo momento e se eles estão<br></br>
          realizando as rotas de maneira correta.</p>
          <p></p>
        </div>
      </ConteudoSlides>
    ),
    
  },
  {
    
    div: (
      <ConteudoSlides>
        <div className="imagemcarrin">
          <img src={foto4} alt="Imagem" height="400px" max-width="100%" max-height="100%"></img>
          <p className="p8">Tenha acesso a um histórico completo das manutenções
          <br></br>usando filtros como veículo, ano e mês e receba lembretes.</p>
          <p></p>
        </div>
      </ConteudoSlides>
    ),
    
  },
  {
    
    div: (
      <ConteudoSlides>
        <div className="imagemcarrin">
          <img src={foto2} alt="Imagem" height="400px" max-width="100%" max-height="100%"></img> 
          <p className="p8">Tenha acesso as localizações posteriores dos veículos, com<br></br>
          endereços, média de velocidade e horário.</p>
        </div>
      </ConteudoSlides>
    ),
    
  },
  {
    
    div: (
      <ConteudoSlides>
        <div className="imagemcarrin">
          <img src={foto6} alt="Imagem" height="400px" max-width="100%" max-height="100%"></img> 
          <p className="p8">Seja avisado todas as vezes que a ignição for acionada,<br></br>
          desligada ou ambos os status.</p>
        </div>
      </ConteudoSlides>
    ),
    
  },

];
