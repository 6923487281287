import React, { useState } from "react";
import { FaUser, FaShoppingCart } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import { SiCashapp } from "react-icons/si";

import { Link } from "react-router-dom";

import logo from "../../../images/diversos/logo rfinds empresarial branca.png";
import {
  Fundo,
  BotaoMenuMobile,
  SecoesBotoes,
  SideBarMenu,
  Hamburguer,
  Logo,
  DivHamburguer,
  Menu,
  Maskmenu,
} from "./styles";

const MenuMobile = () => {
  const [open, setOpen] = useState(false);

  const showSiderBar = () => setOpen(!open);

  return (
    <>
      <Fundo>
        <Link to="/empresa">
          <Logo src={logo} />
        </Link>
        <SecoesBotoes>
          <BotaoMenuMobile>
            <Link
              to="/orcamentoecortedecustos"
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SiCashapp color="#ffe" size="20" />
            </Link>
          </BotaoMenuMobile>
          <a href="http://rcontrol.com.br/rfleet/">
            <FaUser color="#ffe" size="20" />
          </a>
        </SecoesBotoes>
      </Fundo>
    </>
  );
};

export default MenuMobile;
