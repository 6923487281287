import React from "react";

import timelineElements from "./timelineElements";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

import {
  Content,
  Page,
  HistText,
  HistImage,
  ContentInformations,
} from "./styles";

import iconPredio from "../../../images/History/imagem_ranor5.png";
import empresarios from "../../../images/History/empresarios.jpg";
import { AiOutlineStar as StarIcon } from "react-icons/ai";
import { TiGroupOutline as GroupIcon } from "react-icons/ti";
import { RiExchangeLine as ChangeIcon } from "react-icons/ri";
import { BsBuilding as BuildingIcon } from "react-icons/bs";
import { IoTodayOutline as TodayIcon } from "react-icons/io5";

const HistPage = () => {
  let workIconStyles = { background: "#06D6A0" };
  let schoolIconStyles = { background: "#698bd3" };
  return (
    <Content>
      <Page>
        <h2>Conheça um pouco de nossa história</h2>
        <div className="contentImg">
          <img src={iconPredio} className="predio"></img>
          <ContentInformations>
            {/* <HistImage><img src={empresarios}></img></HistImage> */}
          </ContentInformations>

         <section class="grid grid-row-1">
          <div class="item1">2011</div>
          <div class="item2">2015</div>
          <div class="item3">2016</div>
          <div class="item4">2021</div>
        </section>

        <section class="grade">
          <div class="texto1">
            <h1>Recife-PE</h1>
            <p1>A Ranor iniciou sua história em 2011 no Porto Digital de Recife e com o passar dos anos,a empresa foi se aprimorando no ramo de gestão de processos de rastreamento e ganhando espaço por onde percorria.</p1>
          </div>
          <div class="texto2">
            <h1>Recife-PE</h1>
            <p1>Entre 2011 e 2015, a Ranor<br></br> contou com algumas parceiras,<br></br> essas que a estruturaram e <br></br>se dedicaram ao crescimento <br></br>e reconhecimento da empresa.</p1>
          </div>
          <div class="texto3">
            <h1>Passos-MG</h1>
            <p1>No ano de 2016, a empresa,<br></br> já situada em passos, evoluía<br></br>cada dia mais, presente em<br></br>mais de 12 estados no Brasil.</p1>
          </div>
          <div class="texto4">
            <h1>Passos-MG</h1>
            <p1>Já em 2021, a Ranor decide por<br></br>apostar cada dia em mais<br></br>projetos e em seu crescimento,<br></br>sempre valorizando a experiência<br></br>de seus colaboradores, clientes e parceiros.</p1>
          </div>
        </section>

        </div>


        {/* <div class="wrapper" id="area">
        <div class="One" div="um">One</div>
        <div class="Two" div="dois">Two</div>
        <div class="Three" div="tres">Three</div>
        <div class="Four" div="quatro">Four</div>
        </div> */}











        {/* <VerticalTimeline>
          {timelineElements.map((element) => {
            let isStarIcon = element.icon === "Star";
            let isGroupIcon = element.icon === "GroupIcon";
            let isChangeIcon = element.icon === "ChangeIcon";
            let isBuildingIcon = element.icon === "BuildingIcon";
            let isTodayIcon = element.icon === "TodayIcon";

            const demaisIcons = () => {
              if (isGroupIcon) {
                return <GroupIcon />;
              } else if (isChangeIcon) {
                return <ChangeIcon />;
              } else if (isBuildingIcon) {
                return <BuildingIcon />;
              } else if (isTodayIcon) {
                return <TodayIcon />;
              }
            };

            return (
              <VerticalTimelineElement
                key={element.key}
                date={element.date}
                dateClassName="date"
                iconStyle={isStarIcon ? workIconStyles : schoolIconStyles}
                icon={isStarIcon ? <StarIcon /> : demaisIcons()}
              >
                <h3 className="vertical-timeline-element-title">
                  {element.title}
                </h3>
                <h5 className="vertical-time-line-subtitle">
                  {element.location}
                </h5>
                <p id="description">{element.description}</p>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline> */}
      </Page>
    </Content>
  );
};

export default HistPage;
