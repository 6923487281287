import React from "react";
import { Content, ConteudoSlide } from "./styles";
import IndicadorMobile from "../../../images/funcionalidades icones/IndicadorMobile.png";
import { PointerData } from "./PointerData";
import foto6 from "../../../images/funcionalidades icones/6 - IGNNIÇÃO.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";

const PointerRotateMobile = () => {
  let index = 0;
  function setIndex(indexAlt) {
    index = indexAlt;
  }

  return (
    <Content id="FuncionalidadesMobile">
      <div className="titleIndicador">
        <h5>Funcionalidades e Recursos</h5>
        <img src={IndicadorMobile} alt="IndicadorMobile" />
      </div>

      <ConteudoSlide>
        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          loop
          onSlideChange={() => {}}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {PointerData.map((item) => {
            return (
              <SwiperSlide className="Text2">
                {({ isNext }) => (
                  <div>
                    {isNext ? item.div2 : ``}
                    {isNext ? `` : item.div}
                    {isNext ? item.text : ``}
                  </div>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </ConteudoSlide>
    </Content>
  );
};

export default PointerRotateMobile;
