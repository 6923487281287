import { FeedBack, Content, TextoAvaliacao } from "./styles";
// import person from "../../../images/funcionalidades icones/person.png";

export const FeedBackData = [
  {
    div: (
      <>
        <FeedBack className="coment" id="coment1">
          <Content>
            <div className="infoPerson">
              {/* <img src={person} /> */}
              <h3>Rogerio</h3>
            </div>
            <div className="text">
              <p>
                Depois que colocamos o sistema da Ranor, começamos a expandir
                para outros tipos de mercado, pois possuiu ferramentas para
                todos os segmentos.
              </p>
            </div>
          </Content>
        </FeedBack>
      </>
    ),
  },
  {
    div: (
      <>
        <FeedBack className="coment">
          <Content>
            <div className="infoPerson">
              {/* <img src={person} /> */}
              <h3>Caio</h3>
            </div>
            <div className="text">
              <p>
                Estou com a Ranor deste abril de 2015, estou satisfeito com seus
                serviços prestados para minha empresa, tenho bom atendimento
                quando preciso por parte de seus funcionários e a sua plataforma
                de rastreamento é a melhor que já conheci!
              </p>
            </div>
          </Content>
        </FeedBack>
      </>
    ),
  },
  {
    div: (
      <>
        <FeedBack className="coment">
          <Content>
            <div className="infoPerson">
              {/* <img src={person} /> */}
              <h3>Sergio</h3>
            </div>
            <div className="text">
              <p>
                Trabalhamos com outras plataformas de rastreamento, mas sempre
                tínhamos problemas mediante as solicitações do cliente final,
                essa comunicação passou a ser satisfatória assim que começamos a
                trabalhar com a RANOR pois nossa operação exige muito da
                plataforma, temos um serviço específico voltado a gerenciamento
                de frotas de ônibus urbanos. Após a RANOR conseguimos atender
                nossos clientes com máxima agilidade.
              </p>
            </div>
          </Content>
        </FeedBack>
      </>
    ),
  },
];
