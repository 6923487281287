import styled from "styled-components";

export const AreaDownload = styled.div`
  background-color: #082318;
  max-width: 100%;
  height: auto;
  float: left;
  padding: 0;
  z-index: 10;
  @media (min-width: 925px) {
    display: none;
  }
`;

export const EncaixeItens = styled.div`
  max-width: 100%;
  float: none;
`;

export const LeaftDownload = styled.div`
  max-width: 100%;
  height: auto;
  float: left;
  margin: 0;
  overflow: visible;
  background: #efefef;
  border-radius: 0 155px 0 0;
`;

export const RightDownload = styled.div`
  width: 100%;
  height: auto;
  margin: 0;
  background: #007b4b;
  float: right;
  padding: 0;
  overflow: visible;
  position: relative;
  img {
    width: 100%;
    margin: -119px -30px 0 0;
    float: left;
    position: relative;
    top: inherit;
    right: inherit;
    height: auto;
    padding: 0;
    overflow: hidden;
  }
`;

export const LeaftDownloadInfos = styled.div`
  top: -120px;
  position: absolute;
  height: auto;
  float: left;
  margin: 0;
  overflow: visible;
  border-radius: 0 155px 0 0;
  position: relative;
  padding-left: 25px;
  background-color: #fff;

  h5 {
    width: 100%;
    height: auto;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    font-size: 32px;
    line-height: 45px;
    color: #02291a;
    text-align: left;
    font-weight: 700;
    margin-top: 2.5rem;
  }

  h4 {
    width: 100%;
    height: auto;
    float: left;
    margin: 35px 0 0 0;
    padding: 0;
    overflow: visible;
    font-size: 22px;
    line-height: 20px;
    color: #394057;
    text-align: left;
    font-weight: 400;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    width: auto;
    height: auto;
    float: left;
    margin: 40px 0 0 0;
    padding: 0 22px;
    overflow: visible;
    font-size: 12px;
    line-height: 54px;
    width: 10rem;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background: #1f3b7c;
    border-radius: 10px;
    cursor: pointer;
  }

  svg {
    margin-left: 1rem;
  }

  img {
    background-color: #fff;
  }
`;

export const AreaNewsLatter = styled.div`
  width: 100%;
  height: 10%;
  float: left;
  margin: -0px 0 0 0;
  overflow: visible;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  background: #007b4b;
`;

export const UlArea = styled.div`
  width: 100%;
  height: auto;
  float: left;
  padding: 0;
  overflow: visible;

  ul {
    padding: 0;
  }

  ul a {
    margin: 0;
    margin-top: 1.5rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 0;
    width: auto;
    height: auto;
    float: left;
    padding: 0;
    overflow: visible;
  }
`;
