import React from "react";
import { Coments, TextoAvaliacao, TextoDepoimentos } from "./styles";
import { FeedBackData } from "./FeedBackData";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const Feedback = () => {
  return (
    <Coments id="Avaliacoes">
      <TextoAvaliacao>
        <h3>O que nossos clientes dizem</h3>
        <h4>Confira os depoimentos de quem usa e aprova as soluções R-Finds!</h4>
      </TextoAvaliacao>
      {/* <TextoDepoimentos>
        Confira os depoimentos de quem <b>&nbsp; usa e aprova &nbsp;</b> as
        soluções R-Finds!
      </TextoDepoimentos> */}
      <div className="ContentSlideComents">
        <Swiper
          loop
          speed={500}
          // autoplay={{ delay: 5000 }}
          spaceBetween={10}
          slidesPerView={1}
          navigation
        >
          {FeedBackData.map((conteudo) => {
            return <SwiperSlide>{conteudo.div}</SwiperSlide>;
          })}
        </Swiper>
      </div>
    </Coments>
  );
};

export default Feedback;
