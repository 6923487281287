import styled from "styled-components";
import background from "../../../images/funcionalidades icones/imagem_ranor2.png";
import foto6 from "../../../images/funcionalidades icones/6 - IGNNIÇÃO.png";


export const Content = styled.div`
  width: 100%;
  height: 90vh;
  /* background-image: url(${background}); */
  background-position-y: 4rem;
  background-position-x: 40%;
  background-size: 600px;
  background-repeat: no-repeat;
  background-color: #082318;
  @media (max-width: 480px) {
    padding-bottom: 3rem;
  }
  .Text2 {
    transition: ${({ isNext }) => (isNext ? "all 0.8s" : "all 0.8s ease")};
    transform: ${({ isNext }) => (isNext ? "scale(1)" : "scale(1.0)")};
    color: white;
  }
  .titleIndicador {
    font-family: TommyBold;
    letter-spacing: 0.05rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .titleIndicador h5 {
    color: white;
    text-align: center;
    font-size: 1.2rem;
  }

  .titleIndicador img {
    height: 80px;
  }

  @media (min-width: 925px) {
    display: none;
  } ;
`;

export const ConteudoSlide = styled.div`
  .frasePointer {
    font-family: TommyLight;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    white-space: nowrap;
    max-width: 280px;
    white-space: nowrap;
    margin-top: 1rem;
  }
  .itemPointer {
    font-family: TommyRegular;
    letter-spacing: 0.05rem;
    margin: 2rem 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .image2 {
      background-color: #1d284e;
      -moz-transform: scale(1.4);
      -webkit-transform: scale(1.4);
      transform: scale(1.4);
      border-radius: 80%;
      display: -ms-flex;
      display: -webkit-flex;
      border: 1px #beb8b8 solid;
      img {
        padding: 1rem;
        height: 40px;
        width: 40px;
      }
    }
    .image {
      border: 1px green solid;
      border-radius: 200px;
      height: 70px;
      width: 70px;
      background-color: #02291a;

      img {
        padding: 1rem;
        height: 40px;
        width: 40px;
      }
    }
  }
`;
