import { Link } from "react-router-dom";
import styled from "styled-components";

export const Fundo = styled.div`
  background-color: #101422;
  border-bottom: #416d51 1px solid;
  height: 61px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  border-bottom: #394057 1px solid;
  z-index: 0;

  @media (min-width: 1025px) {
    display: none;
  }
`;

export const SecoesBotoes = styled.div`
  display: flex;
  /* flex:1; */
  a {
    border-left: #394057 1px solid;
    width: 60px;
    height: 60px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BotaoMenuMobile = styled(Link)`
  border-left: #394057 1px solid;
  width: 60px;
  height: 60px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 105px;
  height: 26px;
  margin-left: 3.5rem;
`;
