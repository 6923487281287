import styled from "styled-components";

export const Content = styled.div`
  background-color: #02291a;
  width: 100vw;
  position: relative;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 12px 0 rgb(0 0 0 / 45%);
`;
export const Formulario = styled.div`
  background-color: #02291a;
  font-size: 1rem;
  max-width: 1400px;
  width: 150%;
  height: 620px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* box-shadow: 4px 4px 12px 0 rgb(0 0 0 / 45%); */
  border-radius: 0px;
  margin: 40px 0 40px 0;
  margin-top: -31px;

  /* .analytics{
    display: flex;
    flex-direction: column;
  } */
  h1 {
    text-align: center;
    font-family: TommyBold;
    letter-spacing: 0.05rem;
    font-weight: 900;
    color: #fff;
    margin-bottom: 0;
  }

  p {
    font-family: TommyRegular;
    letter-spacing: 0.05rem;
    /* font-style: italic; */
    font-weight: 450;
    padding: 0 2rem 1rem 2rem;
    margin-bottom: 2px;
    border-bottom: 1px solid #007b4b;
    box-shadow: 0px 4px #007b4b;
    color: #fff;
  }

  .divWhats {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    span {
      margin-right: 0 auto;
      font-size: 0.9rem;
      font-weight: 600;
      font-style: italic;
    }
  }

  form {
    margin-top: 7.7rem;
  }

  a {
    display: flex;
    font-style: italic;
    color: black;
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: none;
    align-items: center;
  }

  path {
    color: #007b4b;
  }

  svg.icon {
    margin-right: 0.5rem;
  }

  .espacamentoInputs {
    display: flex;
    <HR SIZE=30 WIDTH=2 ALIGN=right>
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height: 30%; */
    margin-top: 0.3rem;
    border-radius: 5px;
    border: none;
  }

  input {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 30px;
    padding: 10px 0 10px;
    font-size: 16p
    height: 60%;
    margin-top: 0.1rem;

  }

  textarea {
    display: flex;
    margin-bottom: 10px;
    min-width: 102%;
  }

  .espacamentoInputs label {
    width: 100%;
    text-align: left;
    font-family: TommyLight;
    letter-spacing: 0.05rem;
    font-size: 14px;
    font-weight: 700;
    //color: #333;
    line-height: 1.5;
    padding-left: 7px;
    color: white;
  }

  .enviar {
    cursor: pointer;
    background-color: #007b4b;
    margin-top:56px;
    margin-bottom:14px;
    text-transform: uppercase;
    height: 3.5rem;
    width: 100%;
    font-family: TommyBold;
    letter-spacing: 0.05rem;
    color: white;
    border-radius: 50px;

    :hover {
      box-shadow: none;
      opacity: 0.95;
    }
  }

  .primeira{
    width: 30%;
  }

  .Segunda{
    width: 30%;
    margin-top: -10px;
  }

  #marginInput{
    margin-bottom: 40px;
  }

  textarea {
    width: 90%;
    height: 140px;
    /* line-height: 20px; */
    /* max-width: 400px; */
    border-radius: 30px;
    min-width: 100%;
  }
  .spanForm {
    display: flex;
    justify-content: center;
    span {
      font-family: TommyRegular;
      letter-spacing: 0.05rem;
      margin-top: 5px;
    }
  }

  .inputs {
    width: 100%;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
  }

  @media (max-width: 925px) {
    display: flex;

    h1 {
      font-size: 1.6rem;
      margin-bottom: 12px;
    }

    p {
      display: flex;
      font-size: 0.8rem;
    }

    .enviar {
      width: 100%;
      /* margin: 1rem 0; */
      margin-top: 10px;
      border-radius: 0px;
    }

    .divWhats {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      a {
        display: flex;
        color: black;
        font-family: TommyBold;
        letter-spacing: 0.05rem;
        font-style: italic;
        font-weight: 600;
        font-size: 0.9rem;
        text-decoration: none;
        align-items: center;
        margin-top: 0.5rem;
      }
      svg.icon {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const PostForm = styled.div``;
