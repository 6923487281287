import styled from "styled-components";

export const Content = styled.div`
  margin-top: -40px;
  height: 34rem;
  text-align: center;
  justify-content: center;
  background-color: #101422;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  float: left;
  position: relative;
  @media (max-width: 925px) {
    display: none;
  }
`;

export const Mpfooter = styled.div`
  margin-top: 3rem;
  height: 19.25rem;
  display: flex;
  max-width: 999px;
  width: 100%;
  justify-content: center;

  .box-footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;

    h5 {
      height: auto;
      float: left;
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      color: #2be39b;
      font-weight: 700;
      text-align: left;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;
      text-transform: uppercase;
    }
    ul {
      width: 100%;
      height: auto;
      float: left;
      margin: 40px 0 40px 0;
      padding: 0;
      overflow: visible;
      color: white;

      li {
        width: 100%;
        height: auto;
        float: left;
        margin: 18px 0 0 0;
        margin-top: 18px;
        padding: 0;
        list-style: none;

        a {
          text-decoration: none;
          color: #ffff;
        }
        .links {
          cursor: pointer;
        }

        p {
          margin: 0;
          padding: 0;
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
          box-sizing: border-box;

          span {
            font-size: 12px;
          }

          strong {
            color: #2be39b;
            font-size: 1.4rem;
          }
        }
      }
    }
  }
`;

export const Pagamentosfooter = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  border-top: #182333 1px solid;
  border-bottom: #182333 1px solid;
  height: 10.25rem;
  color: white;
  max-width: 999px;
  width: 100%;
  align-items: center;
  .seguranca {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .redesSociais {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
      margin-left: 1rem;
    }

    .iconsRedes {
      a {
        color: white;
        margin: 0 1rem;
      }
    }
  }
`;

export const Rodape = styled.div`
  width: 1170px;
  height: 5.5rem;
  color: white;
  display: flex;
  justify-content: center;
`;
