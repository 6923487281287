import furto from "../../../assets/furto.json";
import EmpresasParceiras from "../../../assets/empresasparceiras.json";
import clientesSatisfeitos from "../../../assets/clientessatisfeitos";
import clientesAtivos from "../../../assets/clientesAtivos.json";

const clientesSatisfeitosOptions = {
  loop: true,
  autoplay: true,
  animationData: clientesSatisfeitos,
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const EmpresasParceirasOptions = {
  loop: true,
  autoplay: true,
  animationData: EmpresasParceiras,
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const furtoOptions = {
  loop: true,
  autoplay: true,
  animationData: furto,
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const ativoOptions = {
  loop: true,
  autoplay: true,
  animationData: clientesAtivos,
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const LottieData = [
  {
    options: clientesSatisfeitosOptions,
  },
  {
    options: furtoOptions,
  },
  {
    options:ativoOptions, 
  },
  {
    options: EmpresasParceirasOptions,
  },
];
