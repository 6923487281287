import styled from "styled-components";
import { Link } from "react-router-dom";

export const Content = styled.div`
@media(max-width: 975px ){
  height: 11vh;
}
  overflow-y: hidden;

  width: 100%;
  height: 6vh;
  display: flex;
  overflow-y: hidden;

  justify-content: center;
  background-color: #727272;
  border-bottom: #727272 3px;
  box-sizing: border-box;
`;

export const Fundo = styled.div`
  max-width: 1300px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Logo = styled.img`
  width: 125px;
  height: 36px;
  margin-top: 8px;
`;
