import React, { useState } from "react";
import { Content, Image } from "./styles";
import dashboard from "../../../images/funcionalidades icones/dashboard.png";
import historico from "../../../images/funcionalidades icones/historico de posições.png";
import relatoriom from "../../../images/funcionalidades icones/relatorios de manutençaõ.png";
import relatorio from "../../../images/funcionalidades icones/relatorios.png";
import status from "../../../images/funcionalidades icones/status de ignicção.png";
import telemetria from "../../../images/funcionalidades icones/telemetria.png";
import tempo from "../../../images/funcionalidades icones/tempo real.png";
import carrin from "../../../images/funcionalidades icones/imagem_ranor2.png";
// import { informationsPoiterData } from "./InformationsPointerData";
// import fundo from "../../../images/funcionalidades icones/fundo.png";
// import indicador from "../../../images/funcionalidades icones/icone indicador localização.png";

import { carrosselData } from "./CarrosselData";

const PointerRot = () => {
  const [positionPointer, setPositionPointer] = useState(0);

  const selectPosition = (num) => {
    setPositionPointer(num);
  };
  const quitePosition = () => {
    setPositionPointer(0);
  };

  const currentActive = (index) => {
    if (positionPointer === index) {
      return true;
    } else {
      return false;
    }
  };

  const getPosition = () => {
    // const box = document.querySelector("#cursor");
    // let boxBoundingRect = box.getBoundingClientRect();
    // let boxCenter = {
    //   x: boxBoundingRect.left + boxBoundingRect.width / 2,
    //   y: boxBoundingRect.top + boxBoundingRect.height / 2,
    // };
    // document.addEventListener("mousemove", (e) => {
    //   let angle =
    //     Math.atan2(e.pageX - boxCenter.x, -(e.pageY - boxCenter.y)) *
    //     (180 / Math.PI);
    //   box.style.transform = `rotate(${angle}deg)`;
    // });
  };

  return (
    <Content onLoad={getPosition} id="Funcionalidades">
      {/* <Image src={carrinho}></Image> */}
      <div className="titulo">
          <h1>Conectividade e Tecnologia: Temos o que você precisa!</h1>
        </div>
      <div className="container">

        <div className="grid-container">
        
        {/* <div className="Meio">
          <img src={indicador} id="cursor"></img>
        </div> */}
        <div className="line-data">
          <div
            className="Caixa"
            onMouseOver={() => selectPosition(0)}
            onMouseOut={() => {
              quitePosition(0);
            }}
          >
            <img src={dashboard}></img>
            <p>Dashboard</p>
          </div>
          <div
            className="Caixa"
            onMouseOver={() => selectPosition(5)}
            onMouseOut={() => {
              quitePosition(0);
            }}
          >
            <img src={historico}></img>
            <p>Relatório de Percurso</p>
          </div>

          <div
            className="Caixa"
            onMouseOver={() => {
              selectPosition(2);
            }}
            onMouseOut={() => {
              quitePosition(0);
            }}
          >
            <img src={relatorio}></img>
            <p>Gestão de Abastecimentos</p>
          </div>
        </div>

          <div className="line-data">
            <div
            className="Caixa"
            onMouseOver={() => {
              selectPosition(4);
            }}
            onMouseOut={() => {
              quitePosition(0);
            }}
          >
            <img src={relatoriom}></img>
            <p>Relatório de Manutenção</p>
          </div>
          <div
          className="Caixa"
          onMouseOver={() => {
            selectPosition(3);
          }}
          onMouseOut={() => {
            quitePosition(0);
          }}
        >
          <img src={tempo}></img>
          <p>Rastreamento em Tempo Real</p>
        </div>
          </div>

        <div className="line-data">
        <div
          className="Caixa"
          onMouseOver={() => {
            selectPosition(6);
          }}
          onMouseOut={() => {
            quitePosition(0);
          }}
        >
          <img src={status}></img>
          <p>Alerta de Ignição</p>
        </div>
        {/* <div className="TelemetriaText">
          <h3>Telemetria</h3>
        </div> */}
        <div
          className="Caixa"
          onMouseOver={() => {
            selectPosition(1);
          }}
          onMouseOut={() => {
            quitePosition(0);
          }}
        >
          <img src={telemetria}></img>
          <p>Telemetria</p>
        </div>
        </div>
        </div>
        {carrosselData.map((carrosel, index) => {
                return (
                  <div className="tamimg" isActive={currentActive(index)} key={index}>
                    {index === positionPointer && carrosel.div}
                  </div>
                );
        })}
      </div>
    </Content>
    
  );
};

export default PointerRot;
