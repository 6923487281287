import React from "react";

import { Link } from "react-router-dom";

import { Ri24HoursFill, RiFacebookBoxLine } from "react-icons/ri";
import { FiYoutube, FiInstagram } from "react-icons/fi";
import { FaPhoneVolume } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

import { Container, Content, SubContainer } from "./styles.js";

const MenuTopo = () => {
  return (
    <Content>
      <Container>
        <SubContainer>
          <a href="https://www.facebook.com/rfinds/">
            <RiFacebookBoxLine size="23" className="facebook" />
          </a>

          <a href="https://www.instagram.com/rfindsoficial/">
            <FiInstagram size="20" className="instagram" />
          </a>

          {/* <a href="https://www.youtube.com/channel/UCtO68r6CJYexihA0FOMA-5Q">
            <FiYoutube size="22" className="youtube" />
          </a> */}
        </SubContainer>

        <SubContainer>
          <div className="linksInformations">
            <FaPhoneVolume color="white" size="18" />
            <p>(35) 3521-9531</p>
          </div>

        </SubContainer>
      </Container>
    </Content>
  );
};

export default MenuTopo;
