import React from "react";
import {
  Content,
  DivDireita,
  DivTexto2,
} from "./styles";
import fundo from "../../../images/ImagensSelect/fundo.jpg";
import { Link } from "react-router-dom";
import { Ri24HoursFill} from "react-icons/ri";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import carros from "../../../images/ImagensSelect/carros.png";
import logored from "../../../images/ImagensSelect/logored.png";
import rast from "../../../images/ImagensSelect/ras.png";

const Select = () => {
  const zoomImage1 = (option) => {
    const imgStyle = document.querySelector("#ImageFundo1").style;
    if (option === "over") {
      imgStyle.transform = "scale(1)";
      imgStyle.transform += "scale(1.2)";
    } else if (option === "out") {
      imgStyle.transform = "scale(1.2)";
      imgStyle.transform += "scale(0.84)";
    }
  };
  
  const zoomImage2 = (option) => {
    const imgStyle = document.querySelector("#ImageFundo2").style;
    if (option === "over") {
      imgStyle.transform = "scale(1)";
      imgStyle.transform += "scale(1.1)";
    } else if (option === "out") {
      imgStyle.transform = "scale(1.2)";
      imgStyle.transform += "scale(0.84)";
    }
  };

  return (
    <Content>
        <DivDireita>

        <DivTexto2 onMouseOver={() => zoomImage2("over")}
          onMouseOut={() => {
            zoomImage2("out");
          }}>
          

          <div className="foto">

            <div className="text">
              <p className="par">Tecnologia em <b>rastreamento</b><br></br> de veículos e frotas!</p>
            </div>

            <div className="rastreamento">
              <img src={rast} className="rast"></img>
            </div>

            <div className="cars">
              <img src={carros} className="Carros"></img>
            </div>
          </div>

          <div className="menu">
            <div className="logo">
              <img src={logored} alt="topo"></img>
            </div>
            <div className="tel">
              <Ri24HoursFill color="#4c4c4c" size="18"/>
              <p> 35 3521-9531</p>
              
            </div>
          </div>


          <div className="textos">
            <div className="caixapessoal">
              <a id="RfindsEmpresarial" href="/empresa">
                <h1 classname="titemp">Rfinds Empresarial</h1>
                <p>
                  Tenha uma visão rápida e completa<br></br>da situação de cada veículo da sua frota<br></br>
                  com apenas um clique!
                </p>
              </a>
          </div>


          <div className="caixaempresa">
          <a id="RfindsPessoal" href="https://rfinds.com.br/pessoal/home">
              <h1>Rfinds Pessoal</h1>
              <p>
              Rápido e prático! Visualize em tempo<br></br>real detalhes de toda a movimentação<br></br>
                do seu veículo.
              </p>
          </a>
            </div>
          </div>

        </DivTexto2>

        
        <img src={fundo} id="ImageFundo2"></img>

        <div className="Fundo"></div>
        
        <div className="redesSociais">
                <a href="https://www.facebook.com/rfinds/" target="_blank" >
                  <FaFacebookF size={25} color="#4c4c4c"/>
                </a>

                <a href="https://www.instagram.com/rfindsoficial/" target="_blank">
                  <FaInstagram size={25} color="#4c4c4c"/>
                </a>

                <a
              href="https://www.linkedin.com/in/rfinds-rastreamento-abb3a31b3/"
              target="_blank"
              >
                <FaLinkedinIn size={25} color="#4c4c4c"/>
              </a>
            </div>
            
      </DivDireita>
    </Content>
  );
};

export default Select;
