import styled from "styled-components";
export const Content = styled.div `
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: column;
  /* #DuvidaLink {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 2;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
    background-color: #0578ba;
    color: white;
    padding: 0.7rem;
    border-radius: 30px;
    transition: all 1s;

    :hover {
      transform: scale(1.05);
    }
  } */

  @media (max-width: 975px) {
    display: none;
  }
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  #RfindsPessoal {
    height: 100%;
    width: 100%;
  }
`;





export const DivDireita = styled.div `
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #e9e9e9;
  #ImageFundo2 {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    object-fit: cover;
    transition: all 3s;
  }

  a {
    text-decoration: none;
  }

  .Fundo {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #e9e9e9;
    opacity: 0.9;
  }
  .redesSociais {
    z-index: 2;
    position: absolute;
    right: 2%;
   // left: 10%;
    bottom: 35%;
    flex-direction: column;
    display: flex;
    /* width: 3%; */

    h5 {
      margin-left: 1rem;
      margin-bottom: 40px;
    }

    a {
        color: white;
        margin-bottom: 40px;
      }
  }
`;





export const DivTexto2 = styled.div `
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  color: white;
  transition: all 1s ease;
  :hover {
    /* transform: scale(1.05); */
    /* background-color: #015c3a; */
    /* opacity: 0.7; */
  }

  .Carros{
    height: 50%;
    position: absolute;
  }

  .menu{
    width: 85rem;
    height: 55px;
    position: absolute;
    display: flex;
    border-bottom: inset;
    justify-content: space-between;

    .logo{
      display: flex;
      width: 277px;
      justify-content: space-between;
      margin-left: 33px;
    }
    img {
      width: 100%;
  }
  }

  .tel{
    color: #4c4c4c;
    flex-direction: row;
    text-decoration: none;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: end;
    z-index: 10000;

    p{
      margin: 15px;
    }
  }

  .foto{
    position: relative;

    .text{
      position: absolute;
      top: 13%;
      width: 400px;
      text-align: justify;
      right: 4rem;
      font-size: 28px;
      color: #4c4c4c;
    }
  
    .cars{
      height: 100%;
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 3;
    }
    .rastreamento{
      height: 90%;
      width: 37rem !important;
      display:flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
    }
  }

  .Fundo {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #e9e9e9;
    opacity: 0.5;
  }

  .textos{
    display: flex;
    justify-content: space-around;
    text-decoration: none;
    width: 60rem;
    position: absolute;
    bottom: 10%;

    h1{
      color: white;
      font-size: 24px;
      margin-bottom: 0px;
      line-height: 27px;
    }
    p{
      color: white;
      margin-bottom: 1px;
      margin: 0;
      height: 70px;
      font-size: 15px;
    }

   .caixapessoal{
    background-color: #0d6745;
    width: 28rem;
    position: relative;
    border-radius: 30px; 
    h1{
      color: white;
      font-size: 24px;
      margin-bottom: 0px;
      line-height: 27px;
    }
    p{
      color: white;
      margin-bottom: 1px;
      margin: 0;
      height: 70px;
      font-size: 15px;
    }
  }  

  .caixaempresa{
    background-color: #254180;
    width: 28rem;
    border-radius: 30px;
    position: relative;
    z-index: 3;
    h1{
      color: white;
      font-size: 24px;
      margin-bottom: 0px;
      line-height: 27px;
    }
    p{
      color: white;
      margin-bottom: 1px;
      margin: 0;
      height: 60px;
      font-size: 16px;
    }

  .img {
    height: 200px;
    width: 200px;
  }
  }

  
  
`;
