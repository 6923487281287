import React from "react";
import { BsArrowRight } from "react-icons/bs";
import objetos from "../../../images/AreaDownload/objetos.png";
import google from "../../../images/AreaDownload/google.png";
import app from "../../../images/AreaDownload/app.png";
import {
  AreaDownload,
  AreaNewsLatter,
  EncaixeItens,
  LeaftDownload,
  LeaftDownloadInfos,
  RightDownload,
  UlArea,
} from "./styles";

const AreaDownloadTotal = () => {
  return (
    <AreaDownload id="AreaDownloadMobile">
      <EncaixeItens>
        <LeaftDownload>
          <LeaftDownloadInfos>
            <h5>Faça download do nosso App</h5>
            <h4>Monitore seu veículo de qualquer dispositivo com internet.</h4>
            <UlArea>
              <ul>
                <a
                  href="https://play.google.com/store/apps/details?id=com.rfinds&hl=pt_BR&gl=US"
                  target="_blank"
                >
                  <img src={google} alt=""></img>
                </a>

                <a
                  href="https://apps.apple.com/br/app/rfinds/id1448734540"
                  target="_blank"
                >
                  <img src={app} alt=""></img>
                </a>
              </ul>
            </UlArea>
          </LeaftDownloadInfos>
        </LeaftDownload>
        <RightDownload>
          <img src={objetos}></img>
        </RightDownload>
      </EncaixeItens>
      <AreaNewsLatter></AreaNewsLatter>
    </AreaDownload>
  );
};

export default AreaDownloadTotal;
