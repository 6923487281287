import styled from "styled-components";

export const AreaVejaTotal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;

  @media (max-width: 925px) {
    display: none;
  }
`;

export const HomeAreaVeja = styled.div`
  display: inline;
  width: 100%;

  h5 {
    font-family: TommyBold;
    letter-spacing: 0.05rem;
    margin: 1.2rem 0 3rem 0;
    width: 100%;
    height: auto;
    float: left;
    padding: 0;
    overflow: visible;
    font-size: 32px;
    line-height: 32px;
    color: #101422;
    text-align: center;
    font-weight: 700;
  }
`;

export const VejaPassos = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;

  ul {
    position: absolute;
    top: 40px
    left: -72px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120%;
    padding: 0;

    text-align: center;
  }

  ul li {
    width: 100%;
    min-width: 182px;
    max-width: 152px;
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;
  }

  ul li .p0 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 110%;
    height: 10rem;
    
    background: 
    linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 50em;
    border: 10px solid transparent;
  }

    /* box-shadow: inset  1px 3px 0px 14px 0 #000, 
            inset 0 66px 15px 0px #11111,
            inset 0 99px 5px 0px #; */
/* 
    box-shadow: 1px 3px 0px 14px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 3px 0px 14px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 3px 0px 14px rgba(0,0,0,0.75); */


    /* box-shadow: 0px 0px 8px 6px #2d6018,4px 0px 0px 10px #d1cc36,0px 10px 21px -3px rgba(0,0,0,0.1); */
    /* box-shadow: -4px 0px 0px 8px #06967b,0px 0px 4px 10px yellow,0px 10px 21px -3px rgba(0,0,0,0.1); */
    /* box-shadow: 5px 0px 12px 8px #06967b,0px 0px 20px 10px yellow,10px 0px 21px -3px rgba(0,0,0,0.1); */
    /* box-shadow: -17px 0px 0px 4px #06967b,13px 0px 0px 4px yellow,0px 10px 21px -3px rgba(0,0,0,0.1),0px 0px 0px 13px yellow; */
    /* box-shadow: -4px 0px 4px 7px #2d6018,0px 0px 4px 14px #d1cc36;
    background-color: #fff;
    transition: all 0.5s; */

  ul li .p1 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 110%;
    height: 10rem;
    
    background: 
    linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 50em;
    border: 10px solid transparent;
  }

  ul li .p2 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 115%;
    height: 10rem;
    
    background: 
    linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 50em;
    border: 10px solid transparent;
  }

  ul li .p3 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 115%;
    height: 10rem;
    
    background: 
    linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 50em;
    border: 10px solid transparent;
  }

  ul li .p4 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 125%;
    height: 10rem;
    
    background: 
    linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 50em;
    border: 10px solid transparent;
  }

  ul li .p5 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 127%;
    height: 10rem;
    
    background: 
    linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
    border-radius: 50em;
    border: 10px solid transparent;
  }

  ul li p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 5px auto;
    font-family: "Arial", sans-serif;
    width: 50%;
    height: auto;
    font-weight: 600;
    color: rgb(96, 121, 141);
    /* background-color: #19c455; */
    border-radius: 50%;
    font-size: 2.5rem;
    position: relative;
  }

  #flecha {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 2rem;
    height: 2rem;
  }

  .icon {
    width: 2rem;
    height: 2rem;
  }
  .icon ::shadow {
    width: 1.5rem;
    height: 1.5rem;
  }

  h4 {
    width: 130%;
    height: auto;
    float: left;
    margin: 30px 0 0 0;
    padding: 0;
    overflow: visible;
    font-size: 14px;
    line-height: 18px;
    color: #082a3f;
    font-weight: 300;
    text-align: center;
  }

  /* #circulo0 {
    background-color: rgb(0, 132, 0);
    box-shadow: 0 0 0 8px rgb(66, 198, 66), 0 0 0 15px rgb(74, 222, 74),
      0 0 0 20px rgb(132, 255, 132), 0px 0px 10px 25px rgba(115, 247, 115, 50%);
    p {
      color: white;
    }
  } */
`;

export const SlideAreaVeja = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
`;

export const SwiperAreaVeja = styled.div``;

export const ItemSwiperAreaVeja = styled.div`
  display: flex;
  width: 1038px;
  border-radius: 15px;
  margin-top: 2rem;
  /* box-shadow: 4px 4px 12px 0 rgb(0 0 0 / 45%); */

  .infos {
    margin: 25px;
    text-align: center;
    width: 100%;
    padding-top: 40px;
    text-align: justify;
  }

  h3 {
    width: 100%;
    font-family: TommyBold;
    height: auto;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    font-size: 32px;
    line-height: 38px;
    color: #02291a;
    text-align: left;
    font-weight: 700;
  }

  img {
    border-radius: 15px 0 0 15px;
  }

  p {
    width: 100%;
    height: auto;
    float: left;
    margin: 0;
    padding: 30px 0 0 0;
    overflow: visible;
    font-size: 16px;
    font-family: TommyLight;
    line-height: 20px;
    color: #000000;
    text-align: left;
    font-weight: 400;
    text-align: justify;
  }
`;

export const ConteudoSlides = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  min-width: 1038px;

  transition: width 2s, height 4s;

  .linkbutton {
    min-width: 228px;
    height: auto;
    float: left;
    margin-top: 2rem;
    padding: 0 22px;
    overflow: visible;
    font-family: TommyBold;
    letter-spacing: 0.05rem;
    font-size: 12px;
    line-height: 44px;
    color: #fff;
    background-color: #041334;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    border-radius: 10px;
    transition: all 0.3s;
    :hover {
      cursor: pointer;
      transform: scale(1.08);
      box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.6);
      fill: #19c455;
      transition: 200ms ease-in-out;
    }
  }
`;

export const DivSwitch = styled.div`
  opacity: ${({ isActive }) => (isActive ? "1" : "0")};
  transition: ${({ isActive }) => (isActive ? "all 1s" : "all 1s ease")};
`;
