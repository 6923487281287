import React, { useState } from "react";

import flecha from "../../../images/iconesAreaVeja/right-arrow.svg";
import backres from "../../../images/iconesAreaVeja/backras.jpg";
import cont7 from "../../../images/iconesAreaVeja/imagem_ranor4.png";

import { carrosselData } from "./CarrosselData";
import {
  AreaVejaTotal,
  HomeAreaVeja,
  VejaPassos,
  SlideAreaVeja,
  SwiperAreaVeja,
  ItemSwiperAreaVeja,
  DivSwitch,
} from "./styles";

const AreaVeja = () => {
  const [indiceClick, setIndiceClick] = useState(0);

  function passo(num) {
    setIndiceClick(num);
    // const divs = document.querySelectorAll(".circulo");
    // divs.forEach((div, key) => {
    //   if (key === num) {
    //     const pStyle = div.children[0].style;
    //     const divStyle = div.style;
    //     divStyle.backgroundColor = "rgb(0, 132, 0)";
    //     divStyle.boxShadow =
    //       "0 0 0 8px rgb(66, 198, 66), 0 0 0 15px  rgb(74, 222, 74) ,0 0 0 20px rgb(132, 255, 132) , 0px 0px 10px 25px rgba(115, 247, 115, 50%)";
    //     pStyle.color = "white";
    //   } else {
    //     const divStyle = div.style;
    //     const pStyle = div.children[0].style;
    //     divStyle.backgroundColor = "rgb(8, 42, 63)";
    //     divStyle.boxShadow = "0 0 0 8px #06967b, 0 0 0 15px #63dbc0";
    //     pStyle.color = "rgb(96, 121, 141)";
    //   }
    // });
  }
  
  const currentActive = (index) => {
    if (indiceClick === index) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <AreaVejaTotal id="ComoUsar">
        <HomeAreaVeja>
          <h5>VEJA COMO É FÁCIL TER RFINDS EM SUA EMPRESA!</h5>
          <div>
          <VejaPassos>
            <img src={cont7} className="circulo"></img>
            <ul>
              
              <li id="passo0"  onClick={() => passo(0)} >
                <div className="p0">
                  <p>1</p>
                </div>

                <h4>
                  Calcule sua <br></br> <strong>ECONOMIA</strong>
                  {/* <img src={cont7} className="circulo"></img> */}
                </h4>
              </li>
              {/* <img id="flecha" src={flecha}></img> */}
              <li id="passo1" onClick={() => passo(1)}>
                <div className="p1">
                  {" "}
                  <p>2</p>
                </div>

                <h4>
                  Solicite um <br></br>
                  <strong>ORÇAMENTO</strong>
                </h4>
              </li>
              {/* <img id="flecha" src={flecha}></img> */}
              <li id="passo2" onClick={() => passo(2)}>
                <div className="p2">
                  <p>3</p>
                </div>

                <h4>
                  Agende as<br></br>
                  <strong>INSTALAÇÕES</strong>
                </h4>
              </li>
              {/* <img id="flecha" src={flecha}></img> */}
              <li id="passo3" onClick={() => passo(3)}>
                <div className="p3">
                  <p>4</p>
                </div>

                <h4>
                  Acesse a<br></br>
                  <strong>PLATAFORMA</strong>
                </h4>
              </li>
              {/* <img id="flecha" src={flecha}></img> */}
              <li id="passo4" onClick={() => passo(4)}>
                <div className="p4">
                  <p>5</p>
                </div>

                <h4>
                  Acompanhe em<br></br>
                  <strong>TEMPO REAL</strong>
                </h4>
              </li>
              {/* <img id="flecha" src={flecha}></img> */}
              <li id="passo5" onClick={() => passo(5)}>
                <div className="p5">
                  <p>6</p>
                </div>

                <h4>
                  Gere diversos<br></br>
                  <strong>RELATÓRIOS</strong>
                </h4>
              </li>
            </ul>  
          </VejaPassos>
          </div>
        </HomeAreaVeja>
        <SlideAreaVeja>
          <SwiperAreaVeja>
            <ItemSwiperAreaVeja>
              {carrosselData.map((carrosel, index) => {
                return (
                  <DivSwitch isActive={currentActive(index)} key={index}>
                    {index === indiceClick && carrosel.div}
                  </DivSwitch>
                );
              })}
            </ItemSwiperAreaVeja>
          </SwiperAreaVeja>
        </SlideAreaVeja>
      </AreaVejaTotal>
    </>
  );
};

export default AreaVeja;
