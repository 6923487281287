import styled from "styled-components";

export const AreaDownload = styled.div`
  width: 100%;
  height: auto;
  float: left;
  margin: -300px 0 0 0;
  padding: 0;
  position: relative;
  z-index: 10;

  @media (max-width: 925px) {
    display: none;
  }
`;

export const EncaixeItens = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  float: none;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    width: 380px;
    height: 710px;
    float: left;
    margin: 0 0 0 -380px;
    padding: 0;
    overflow: visible;
    position: absolute;
    top: 0;
    left: 0;
    background: #efefef;
  }
`;

export const LeaftDownload = styled.div`
  width: 100%;
  height: auto;
  float: left;
  margin: 0;
  padding: 100px 0 70px 0;
  overflow: visible;
  background: #efefef;
  border-radius: 0 100px 0 0;
  position: relative;
`;

export const RightDownload = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 0;
  height: auto;
  float: right;
  margin: 70px 0 0 0;
  padding: 0;
  overflow: visible;
  position: relative;

  img {
    display: flex;
    justify-content: flex-end;
    width: auto;
    height: auto;
    margin: 0 -485px 0 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    max-width: 80rem;
    @media (max-width: 1300px) {
      margin: 20px -260px 0 0;
      max-width: 62rem;
    }
    @media (max-width: 1200px) {
      max-width: 50rem;
    }
    @media (max-width: 1000px) {
      max-width: 40rem;
    }
  }
`;

export const LeaftDownloadInfos = styled.div`
  width: 400px;
  height: auto;
  float: left;
  margin: 0;
  padding: 0;
  overflow: visible;
  position: relative;
  z-index: 10;
  padding-left: 5rem;

  h5 {
    width: 100%;
    height: auto;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    font-size: 42px;
    line-height: 45px;
    color: #02291a;
    text-align: right;
    font-weight: 700;
  }

  h4 {
    width: 100%;
    height: auto;
    float: left;
    margin: 40px 0 0 0;
    padding: 0;
    overflow: visible;
    font-size: 22px;
    line-height: 28px;
    color: rgb(0, 0, 0);
    text-align: right;
    font-weight: 400;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    width: auto;
    height: auto;
    float: right;
    margin: 40px 0 0 0;
    padding: 0 22px;
    overflow: visible;
    font-size: 12px;
    line-height: 44px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background: #1f3b7c;
    border-radius: 10px;
    cursor: pointer;
  }

  svg {
    margin-left: 1rem;
  }

  img {
    background-color: #fff;
  }
`;

export const AreaNewsLatter = styled.div`
  width: 150%;
  height: 100%;
  float: left;
  margin: -0px 0 0 0;
  padding: 255px 0 0 0;
  overflow: visible;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  background: #02291a;
`;

export const UlArea = styled.div`
  width: auto;
  height: auto;
  float: right;
  margin: 65px 0 0 0;
  padding: 0;
  overflow: visible;

  ul a {
    width: auto;
    height: auto;
    float: left;
    margin: 0 0 0 15px;
    padding: 0;
    overflow: visible;
    opacity: 0.6;
    transition: 0.3s;
  }

  ul a img {
    width: auto;
    height: auto;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    border-radius: 8px;
  }

  ul :hover {
    background-color: white;
    box-shadow: 3px 3px black;
    cursor: pointer;
    border-radius: 8px;
    opacity: 100%;
  }
`;
