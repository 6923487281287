import React, { useState } from "react";
import { Content, ContentSlides } from "./styles";
import { SliderDataMobile } from "./SliderDataMobile";
import { LottieData } from "./LottieData";
import Lottie from "react-lottie";
import tela3 from "../../../images/ilustration/Tela-0.png";
import { Link } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/components/effect-fade/effect-fade.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]);

const HomeSlideMobile = () => {
  return (
    <>
      <Content id="HomeMobile">
        {/* <div className="lottie-whatsapp">
          <a href="http://wa.me/553535219531?text=Ol%C3%A1,%20tudo%20bem?%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento%20com%20a%20RFinds%20-%20%20%20Rastreamentos.">
            <Lottie
              options={LottieData[0].options}
              isClickToPauseDisabled
              height={50}
              width={50}
            />
          </a>
        </div> */}
        <ContentSlides>
          {/* <Swiper
            fadeEffect={{ crossFade: true }}
            speed={500}
            virtualTranslate={true}
            effect="fade"
            autoplay={{ delay: 4500 }}
            spaceBetween={50}
            slidesPerView={1}
          >
            {SliderDataMobile.map((conteudo, key) => {
              return <SwiperSlide>{conteudo.div}</SwiperSlide>;
            })}
          </Swiper> */}
          <div className="content">
        <div className="divTextButton">
          <div className="areaTextWeb">
            <h1>Rastreie a frota<br></br> da sua empresa!</h1>
            <p>
              Saiba mais do que a localização dos seus veículos!
            </p>
            <p1>
              Temos ferramentas que vão te auxiliar com a <br></br>gestão e economia da logística de sua empresa.
            </p1>
          </div>

          <div className="buttons">
            <Link to="/orcamentoecortedecustos" className="botaolink">
              Calcule agora a economia que você terá
            </Link>
            <a
              href="http://wa.me/553535219531?text=Ol%C3%A1,%20tudo%20bem?%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento%20com%20a%20RFinds%20-%20%20%20Rastreamentos."
              target="_blank"
              className="botaolink2"
            >
              Solicite já o seu orçamento
            </a>
          </div>
        </div>

        <div className="divIllustration">
          <img src={tela3} alt="Imagem" />
        </div>
      </div>
        </ContentSlides>
      </Content>
    </>
  );
};

export default HomeSlideMobile;
