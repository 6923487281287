import React from "react";
import { Content, Mpfooter, Pagamentosfooter, Rodape } from "./styles";
import formasdepagamentosimage from "../../../images/footer/formas_pagamento.png";
import blindado from "../../../images/footer/blindado.png";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Content>
      <Mpfooter>
        <div className="box-footer">
          <h5>Navegue</h5>
          <ul>
            <li>
              <Link
                to="/empresa"
                style={{
                  textDecoration: "none",
                  color: "white",
                  width: "100%",
                  height: "100%",
                  
                }}
              >
                Home
              </Link>
            </li>
          </ul>
        </div>
        <div className="box-footer">
          <ul></ul>
        </div>
        <div className="box-footer"></div>
        <div className="box-footer">
          <h5>Atendimentos</h5>
          <ul>
            {/* <li>
              <p>
                <span>Suporte 24 horas</span>
              </p>
            </li>
            <li>
              <p>
                <span>Seg. à Sex. das 9h às 18h</span>
              </p>
            </li>
            <li>
              <p>
                <span>Seg. à Sex. das 9h às 18h</span>
              </p>
            </li> */}
            <li>
              <p>
                (35) 3521-9531
              </p>
            </li>
          </ul>
        </div>
      </Mpfooter>
      <Pagamentosfooter>
        <div className="seguranca">
          <h5>Certificado de Segurança</h5>
          <figure>
            <img src={blindado}></img>
          </figure>
        </div>
        <div className="redesSociais">
          <h5>Redes Sociais</h5>
          <div className="iconsRedes">
            <a href="https://www.facebook.com/rfinds/">
              <FaFacebookF size={25} />
            </a>
            <a href="https://www.instagram.com/rfindsoficial/">
              <FaInstagram size={25} />
            </a>
            <a
              href="https://www.linkedin.com/in/rfinds-rastreamento-abb3a31b3/"
              target="_blank"
              >
                <FaLinkedinIn size={25}/>
              </a>
          </div>
        </div>
      </Pagamentosfooter>
      <Rodape>
        <p>
          © 2021 - <strong>R-finds Empresarial</strong> Todos os direitos reservados.
        </p>
      </Rodape>
    </Content>
  );
};

export default Footer;
