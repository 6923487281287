import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import { carrosselData } from "./CarrosselData";
import { AreaVejaTotal, HomeAreaVeja, ItemSwiperAreaVeja } from "./styles";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const AreaVeja = () => {
  return (
    <AreaVejaTotal id="ComoUsarMobile">
      <HomeAreaVeja>
        <h5>VEJA COMO É FÁCIL TER RFINDS EM SUA EMPRESA!</h5>
      </HomeAreaVeja>
      <ItemSwiperAreaVeja>
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {carrosselData.map((carrosel) => {
            return <SwiperSlide>{carrosel.div}</SwiperSlide>;
          })}
        </Swiper>
      </ItemSwiperAreaVeja>
    </AreaVejaTotal>
  );
};

export default AreaVeja;
