import styled from "styled-components";
export const Image = styled.img`
  width: 1440px;
  z-index: -10;
  position: absolute;
`;

export const ConteudoSlides = styled.div`

`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 3rem;
  padding-bottom: 27.4rem;
  background: #02291a;
  flex-direction: column;
  @media (max-width: 925px) {
    display: none;
  }
  #Funcionalidades{
    background: green;
  }
  .grid-container {
    display: flex;
    margin-top: -25px;
    flex-direction: column;
  }

  .tamimg{
    max-width: 400px;
  }
  

  /* img{
      width: 100%;
      height: 100%;
  } */
  /* .grid-container {
    display: grid;
    /* margin-top: 5.6rem; 
    grid-template-columns: 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem;
    grid-template-rows: 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem 5.3rem;
    gap: 0em 0em;
    grid-template-areas:
      " . . . . . . . . Dashboard . .  . . . .  "
      " . . . . . Telemetria . . DashboardText . . GestaoVencimentos . . .  "
      " . . . . . TelemetriaText . . . . . GestaoVencimentosText . . .  "
      ". . . . . . . . Meio  . . . . . .  "
      " . . . . RelatorioPercurso . . . . . . . relatorioManut . .  "
      " . . . . RelatorioPercursoText  . . . TextoInfo . . . relatorioManutText . .  "
      " . . . . . . . . . . . . . . .  "
      " . . . . . . RastreamentoTempoReal . . . StatusIgnicao . . . .  "
      " . . . . . . RastreamentoTempoRealText . . . StatusIgnicaoText . . . .  ";
    font-family: TommyBold;
    letter-spacing: 0.05rem;
  } */
  /* .Meio {
    display: flex;
    flex-direction: column;
    img {
      margin-left: 0.8rem;
      margin-top: 3.7rem;
      width: 5.4rem;
    }
    border-radius: 3rem;
    grid-area: Meio;
  } */
  .TextoInfo {
    left: -50%;
    right: -50%;
    top: 48%;
    position: absolute;
    text-align: center;
    border-radius: 3rem;
    grid-area: TextoInfo;
  }
  .DashboardText {
    /* color: white;
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    left: 0;
    /* grid-area: DashboardText; 
    h3 {
      font-weight: 500;
    } */
  }
  .RelatorioPercursoText {
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0.85rem;
    left: 0;
    grid-area: RelatorioPercursoText;
    h3 {
      font-weight: 500;
    }
  }
  .relatorioManutText {
    color: white;
    display: flex;
    justify-content: center;
    display: flex;
    text-align: center;
    font-size: 0.8rem;
    left: 0;
    grid-area: relatorioManutText;
    h3 {
      font-weight: 500;
    }
  }
  .container{
    display: flex;
  }
  .RastreamentoTempoRealText {
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem;
    grid-area: RastreamentoTempoRealText;
    h3 {
      font-weight: 500;
    }
  }

  .p8{
    color: white;
    width: 50rem;
  }
  .p9{
    color: white;
    font-weight: bold;
    width: 30rem;
  }

  .StatusIgnicaoText {
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem;
    grid-area: StatusIgnicaoText;
    h3 {
      font-weight: 500;
    }
  }

  .historico{
    height: 100px;
  }
  .line-data{
    width: 33rem;
    display: flex;
    margin-top: 30px;
  }

  h1 {
      font-family: TommyBold;
      font-size: 2.5rem;
      text-align: center;
      color: #fff
      
    }
  .Caixa {
    color: white;
    display: flex;
    text-align: center;
    flex-direction: column;
    /* position: absolute; */
    img {
      width: 3.2rem;
      /* height: 100px; */
    }
    width: 100%;
    max-width: 133px;
    height: 100%;
    min-height: 100px;

    float: left;

    margin: 4px;
    padding: 10px;
    overflow: visible;
    background: #008000;
    border-radius: 10px;

    /* border-radius: 50%; */
    /* display: -ms-flex;
    display: -webkit-flex; */
    display: flex;

    align-items: center;

    justify-content: center;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    /* box-shadow: 0px 0px 0px 5px rgb(02 255 26 / 35%); */
    /* border: 1px #beb8b8 solid; */

    grid-area: Dashboard;
    :hover {
      background-color: #1d284e;
      -moz-transform: scale(1.3);
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
      /* border-radius: 80%; */
      display: -ms-flex;
      display: -webkit-flex;
    }
  }

  .GestaoVencimentos {
    display: flex;
    flex-direction: column;
    img {
      width: 3.3rem;
    }
    width: 100%;
    height: 100%;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: #02291a;

    /* border-radius: 50%; */
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;

    align-items: center;

    justify-content: center;

    transition: all 200ms ease-in-out;
    box-shadow: 0px 0px 0px 5px rgb(02 255 26 / 35%);
    border: 1px #beb8b8 solid;
    grid-area: GestaoVencimentos;
    :hover {
      background-color: #1d284e;
      -moz-transform: scale(1.3);
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
      /* border-radius: 80%; */
      display: -ms-flex;
      display: -webkit-flex;
    }
  }
  .DashboardText {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 0.85rem;
    left: 0;
    grid-area: DashboardText;
    h3 {
      font-weight: 500;
    }
  }
  .GestaoVencimentosText {
    color: white;
    display: flex;
    justify-content: center;
    left: 0;
    font-size: 0.8rem;

    text-align: center;
    grid-area: GestaoVencimentosText;
    h3 {
      font-weight: 500;
    }
  }

  .relatorioManut {
    display: flex;
    flex-direction: column;
    img {
      width: 3.3rem;
    }
    width: 100%;
    height: 100%;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: #02291a;

    /* border-radius: 50%; */
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;

    align-items: center;

    justify-content: center;

    transition: all 200ms ease-in-out;
    box-shadow: 0px 0px 0px 5px rgb(02 255 26 / 35%);
    border: 1px #beb8b8 solid;
    grid-area: relatorioManut;
    :hover {
      z-index: 10;
      background-color: #1d284e;
      -moz-transform: scale(1.3);
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
      /* border-radius: 80%; */
      display: -ms-flex;
      display: -webkit-flex;
    }
  }
  .StatusIgnicao {
    display: flex;
    flex-direction: column;
    img {
      width: 2.8rem;
    }
    width: 100%;
    height: 100%;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: #02291a;
    /* border-radius: 50%; */
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    box-shadow: 0px 0px 0px 5px rgb(02 255 26 / 35%);
    border: 1px #beb8b8 solid;
    grid-area: StatusIgnicao;
    :hover {
      background-color: #1d284e;
      -moz-transform: scale(1.3);
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
      /* border-radius: 80%; */
      display: -ms-flex;
      display: -webkit-flex;
    }
  }
  .RastreamentoTempoReal {
    display: flex;
    flex-direction: column;
    img {
      width: 2.7rem;
    }
    width: 100%;
    height: 100%;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: #02291a;
    /* border-radius: 50%; */
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    box-shadow: 0px 0px 0px 5px rgb(02 255 26 / 35%);
    border: 1px #beb8b8 solid;
    grid-area: RastreamentoTempoReal;
    :hover {
      background-color: #1d284e;
      -moz-transform: scale(1.3);
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
      /* border-radius: 80%; */
      display: -ms-flex;
      display: -webkit-flex;
    }
  }
  .RelatorioPercurso {
    display: flex;
    flex-direction: column;
    img {
      width: 3.1rem;
    }
    width: 100%;
    height: 100%;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: #02291a;
    /* border-radius: 50%; */
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    box-shadow: 0px 0px 0px 5px rgb(02 255 26 / 35%);
    border: 1px #beb8b8 solid;
    grid-area: RelatorioPercurso;
    :hover {
      z-index: 10;
      background-color: #1d284e;
      -moz-transform: scale(1.3);
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
      /* border-radius: 80%; */
      display: -ms-flex;
      display: -webkit-flex;
    }
  }

  .Telemetria {
    display: flex;
    flex-direction: column;
    img {
      width: 2.6rem;
    }

    width: 100%;
    height: 100%;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: #02291a;

    /* border-radius: 50%; */
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    box-shadow: 0px 0px 0px 5px rgb(02 255 26 / 35%);
    border: 1px #beb8b8 solid;
    grid-area: Telemetria;

    :hover {
      background-color: #1d284e;
      -moz-transform: scale(1.3);
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
      /* border-radius: 80%; */
      display: -ms-flex;
      display: -webkit-flex;
    }
  }
  .TelemetriaText {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 0.85rem;
    left: 0;
    grid-area: TelemetriaText;
    h3 {
      font-weight: 500;
    }
  }
`;


export const Texto = styled.h2`
  font-family: TommyLight;
  z-index: -9;
  color: white;
  text-shadow: 2px 2px #000;
  font-size: 1.3rem;
`;
