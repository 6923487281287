import React, { Component, useState } from "react";
import { Content, Formulario } from "./styles";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import axios from "axios";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";

const Form = () => {


  //COMEÇA AQUI

  const [state, setstate] = useState({
    tipo: "3",
    name: "",
    company: "",
    phone: "",
    email: "",
    qtd_veicles: "",
    depoiment: "",
  });
  const form = {};
  async function sendEmail(form) {
    form.chip = "Não foi declarado";
    form.equip = "Não foi declarado";
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      "https://rcontrol.com.br/siteranor/sendemail.php?form=" +
        JSON.stringify(form)
    );
    xhr.headers = {
      "Content-Type": "application/json",
    };
    xhr.send();
  }
  async function submitHandler() {
    if (
      state.name === "" ||
      state.phone === "" ||
      state.email === "" ||
      state.depoiment === ""
    ) {
      Swal.fire({
        title: "Preencha todos os campos *obrigatórios",
        icon: "error",
      });
    } else {
      form.name = state.name;
      form.email = state.email;
      form.tel = state.phone;
      form.quantVeic = state.qtd_veicles;
      form.company = state.company;
      form.depoiment = state.depoiment;

      form.tipo = 3;
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        "https://rcontrol.com.br/siteranor/send.php?form=" +
          JSON.stringify(form)
      );
      xhr.headers = {
        "Content-Type": "application/json",
      };
      xhr.send();
      /* pegar error do xhr */
      xhr.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          Swal.fire({
            title: "Obrigado!",
            text: "Em breve entraremos em contato!",
            icon: "success",
            confirmButtonText: "Ok",
          });
          sendEmail(form);
        } else {
        }
      };
    }
  }



  function changeHandler(e) {
    setstate({ ...state, [e.target.name]: e.target.value });
  }
  return (
    <Content>
      <Formulario name="form" id="Formulario">
        <h1>Converse conosco!</h1>
        <p>
          Preencha esses campos para solicitar orçamentos, sugerir novas
          funcionalidades, enviar propostas e entrar em contato conosco.
        </p>
        <form className="inputs" id="analytics" >

          <div className="Segunda Coluna">
            <div className="espacamentoInputs">
              <label>Nome</label>
              <input
                type="text"
                value={state.name}
                onChange={changeHandler}
                className="nome"
                required="required"
                name="name"
                style={{
                  backgroundColor: "rgb(227, 229, 227)",
                }}
              />
            </div>


            <div className="espacamentoInputs">
              <label>E-mail</label>
              <table border="0" width="100%" cellpadding="10">
              <input
                className="emailInput"
                type="email"
                value={state.email}
                onChange={changeHandler}
                required="required"
                name="email"
                style={{
                  backgroundColor: "rgb(227, 229, 227)",
                }}
              />
              </table>
            </div>
            

            <div className="espacamentoInputs">
              <label>Deixe sua mensagem</label>
              <table border="0" width="100%" cellpadding="10">
              <textarea
                value={state.depoiment}
                onChange={changeHandler}
                required="required"
                name="depoiment"
                style={{
                  backgroundColor: "rgb(227, 229, 227)",
                }}
              ></textarea>
              </table>
            </div>
            </div>


          <div className="primeira coluna">
          <div className="espacamentoInputs">
              <label>Nome da empresa (Opcional)</label>
              <input
                type="text"
                value={state.company}
                onChange={changeHandler}
                className="company"
                name="company"
                style={{
                  backgroundColor: "rgb(227, 229, 227)",
                }}
              />
            </div>

            <div className="espacamentoInputs">
              <label>Número de telefone</label>
              <InputMask
                type="tel"
                value={state.phone}
                onChange={changeHandler}
                required="required"
                name="phone"
                mask="(99)99999-9999"
                style={{
                  backgroundColor: "rgb(227, 229, 227)",
                }}
              />
            </div>

            <div className="espacamentoInputs">
              <label>Quantidade de veículos (Opcional)</label>
              <table border="0" width="100%" cellpadding="10">
              <input
                type="number"
                value={state.qtd_veicles}
                onChange={changeHandler}
                className="qtd_veicles"
                name="qtd_veicles"
                style={{
                  backgroundColor: "rgb(227, 229, 227)",
                }}
              />
              </table>
            </div>

          <button
            className="enviar"
            id="botaoAnalytics"
            onClick={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            Enviar
          </button>
          </div>
        </form>
      </Formulario>
    </Content>
  );
};
export default Form;