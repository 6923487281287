import styled from "styled-components";

export const Content = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: column;
  @media (min-width: 975px) {
    display: none;
  }
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  #RfindsPessoal {
    height: 100%;
    width: 100%;
  }

  #ImageFundo2 {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    object-fit: cover;
    transition: all 3s;
  }
`;
export const DivDireita = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #e9e9e9;
  
  #ImageFundo2 {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    object-fit: cover;
    animation: zoom 35s;
    @keyframes zoom {
      0% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  a {
    text-decoration: none;
  }
  .Fundo {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #e9e9e9;
    opacity: 0.9;
  }
  .redesSociais {
    z-index: 1213122133123213123123;
    position: absolute;
    right: 32%;
    bottom: -5%;
    /* flex-direction: column; */
    display: flex;

    .fb, .insta, .zap{
      margin-right: 15px
    }
    
    h5 {
      margin-left: 1rem;
      /* margin-lef: 40px; */
    }

    a {
        color: white;
        margin-bottom: 40px;
      }
  }
`;

export const DivTexto2 = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  color: white;
  transition: all 1s ease;

  .menu{
    width: 25rem;
    height: 55px;
    position: absolute;
    display: flex;
    justify-content: space-around;
    border-bottom: inset;
    
    .logo{
      display: flex;
      width: 350px;
      position: absolute;
      justify-content: space-between;
    }
    img {
      width: 200px;
  }
  }

  .tel{
    color: white;
    flex-direction: row;
    text-decoration: none;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: end;
    z-index: 10000;
    color: #4c4c4c;
    

    p{
      margin: 25px;
    }
  }

  .foto{
    /* position: relative; */

    .text{
      position: absolute;
      top: 12%;
      width: 288px;
      /* text-align: justify; */
      right: 2rem;
      color: #4c4c4c;
      font-size: 20px;
    }
  
    .cars{
      height: 94%;
      left: 30px;
      width: 86% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 3;

      .Carros{
        width: 100%;
      }
    }
    .rastreamento{
      left: 65px;
      height: 95%;
      width: 25rem !important;
      display:flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;

      .rast{
        width: 100%;
        z-index: 2;
      }
    }
  }

  .textos{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-decoration: none;
    width: 17rem;
    position: absolute;
    bottom: 15%;


   .caixapessoal{
    background-color: #0d6745;
    width: 16rem;
    height: 80px;
    border-radius: 30px; 
    margin: 10px;
    padding: 0px;
    line-height: 14px;
    z-index: 1010100101333333333333;
    h1{
      color: white;
      font-size: 20px;
      margin-bottom: 8px;
      line-height: 10px;
    }
    p{
      color: white;
      margin-bottom: 1px;
      margin: 0;
      height: 70px;
      font-size: 10px;
    }
    
  }  

  .caixaempresa{
    background-color: #254180;
    width: 16rem;
    height: 80px;
    border-radius: 30px; 
    margin: 10px;
    line-height: 14px;
    z-index: 1010100101333333333333333333;
    padding: 0px;
    /* position: absolute; */
    h1{
      color: white;
      font-size: 20px;
      margin-bottom: 8px;
      line-height: 10px;
    }
    p{
      color: white;
      margin-bottom: 1px;
      margin: 0;
      height: 70px;
      font-size: 10px;
    }
  }

  .img {
    height: 200px;
    width: 200px;
  }
`;
