import styled from "styled-components";

export const Content = styled.div`
  overflow-y: hidden;

  border-bottom: #416d51 3px solid;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 5vh;
  background-color: #101422;
  box-sizing: border-box;
  @media (max-width:975px){
    display: none;
  }

  /* background-color: rgb(0, 153, 51); */
`;

export const Container = styled.div`
  max-width: 1170px;
  width: 100%;
  max-width: 1300px;


  display: flex;

  justify-content: space-between;
  /* padding:0 30px; */
`;

export const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;

  .linksInformations {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
    padding: 0 10px;

    .facebook {
      color: white;
      :hover {
        color: #19c455;
      }
    }

    .instagram {
      color: white;
      :hover {
        color: #19c455;
      }
    }

    .youtube {
      color: white;
      :hover {
        color: #19c455;
      }
    }

    :hover {
      p {
        color: #19c455;
        transition: 200ms ease-in-out;
      }
    }

    p {
      color: white;
      font-weight: 650;
      font-size: 12px;
      padding: 0 5px;
    }
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
    padding: 0 10px;

    .facebook {
      color: white;
      :hover {
        color: #19c455;
      }
    }

    .instagram {
      color: white;
      :hover {
        color: #19c455;
      }
    }

    .youtube {
      color: white;
      :hover {
        color: #19c455;
      }
    }

    :hover {
      p {
        color: #19c455;
        transition: 200ms ease-in-out;
      }
    }

    p {
      color: white;
      font-weight: 650;
      font-size: 12px;
      padding: 0 5px;
    }
  }
`;
