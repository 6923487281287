import React from "react";
import { InformationsHotBar } from "./styles";
import Lottie from "react-lottie";
import { LottieData } from "./LottieData";

const InformationsBar = (slides) => {
  return (
    <>
      <InformationsHotBar>
        <ul>
          <li>
            <Lottie
              options={LottieData[0].options}
              isClickToPauseDisabled
              height={150}
              width={170}
            />
            <span>98%</span>
            <p>
              Clientes <br></br>
              satisfeitos
            </p>
          </li>
          <li>
            <Lottie
              options={LottieData[1].options}
              isClickToPauseDisabled
              height={100}
              width={170}
            />
            <span>437</span>
            <p>
              Veiculos <br></br> recuperados
            </p>
          </li>
          <li>
            <Lottie
              options={LottieData[2].options}
              isClickToPauseDisabled
              height={100}
              width={170}
            />
            <span>25.768</span>
            <p className="ep2">
              Clientes<br></br>ativos
            </p>
          </li>
          <li>
            <Lottie
              options={LottieData[3].options}
              isClickToPauseDisabled
              height={100}
              width={170}
            />
            <span>247</span>
            <p className="ep2">
              Empresas<br></br>parceiras
            </p>
          </li>
        </ul>
      </InformationsHotBar>
    </>
  );
};

export default InformationsBar;
