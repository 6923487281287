import styled from "styled-components";

export const Coments = styled.div`
  width: 100%;
  margin: 0;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-bottom: 2rem;
  margin-top: 50px;
  background-color: #fff
  background-image: linear-gradient(#ffffff, rgb(243, 242, 240));

  @media (min-width: 925px) {
    display: none;
  }

  .arrow {
    display: none;
    margin: 0 2rem;
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: all 0.2s;

    :hover {
      opacity: 0.9;
      transform: scale(1.2);
    }
  }

  #coment1 {
    display: flex;
  }

  .ContentSlideComents {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0;
  }
`;

export const FeedBack = styled.div`
  background-color: #02291a;
  /* padding-bottom: 1rem; */
  height: 100%;
  box-sizing: inherit;
  width: 93%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 23px 23px 1rem 1rem;
  -webkit-box-shadow: 0px 0px 28px -3px rgba(0,0,0,0.65); 
  box-shadow: 0px 0px 28px -3px rgba(0,0,0,0.65);
`;

export const Content = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  background-color: #ffffff;
  border-radius: 30px 30px 20px 20px;
  height: 100%;

  .infoPerson {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;

    img {
      margin-top: 0.5rem;
      height: 100px;
      width: 100px;
    }
    h3 {
      font-size: 1.3rem;
    }
  }

  .text {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    width: 86%;
    text-align: justify;
    font-family: TommyLight;
  }
`;

export const TextoAvaliacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h3 {
    font-size: 1.5rem;
    color: #1f3b7c;
    font-family: TommyBold;
    letter-spacing: 0.05rem;
    margin-bottom: 0;
  }
  :after {
    content: "";
    width: 70px;
    height: 2px;
    float: left;
    margin-top: 20px;
    background: #1f3b7c;
    border-radius: 2.5px;
    left: 50%;
  }
`;

export const TextoDepoimentos = styled.div`
  font-family: TommyRegular;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  text-align: center;
`;
