import styled from "styled-components";

export const AreaVejaTotal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0rem 0 2rem;
  @media (min-width: 925px) {
    display: none;
  }
`;

export const HomeAreaVeja = styled.div`
  display: inline;
  h5 {
    font-family: TommyBold;
    letter-spacing: 0.05rem;
    text-align: center;
    font-size: 1.5rem;
    color: #02291a;
  }
`;

export const ItemSwiperAreaVeja = styled.div`
  border-radius: 15px;

  box-shadow: 4px 4px 12px 0 rgb(0 0 0 / 45%);

  width: 93%;
  .infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px;
    text-align: center;
    padding-top: 10px;
    text-align: justify;

    a {
      text-decoration: none;
    }
  }

  h3 {
    font-family: TommyBold;
    letter-spacing: 0.05rem;
    width: 100%;
    height: auto;
    float: left;
    margin: 0;
    padding: 0;
    overflow: visible;
    font-size: 1.2rem;
    line-height: 38px;
    color: #02291a;
    font-weight: 700;
    text-align: center;
  }

  img {
    border-radius: 15px;
  }

  p {
    font-family: TommyLight;

    width: 100%;
    height: auto;
    float: left;
    margin: 0;
    padding: 10px 0 0 0;
    overflow: visible;
    font-size: 16px;
    line-height: 20px;
    color: black;
    text-align: left;
    font-weight: 400;

    text-align: justify;
  }
`;

export const ConteudoSlides = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: width 2s, height 4s;
  img {
    display: flex;
    align-items: center;
    max-width: 95%;
  }

  .linkbutton a {
    font-family: TommyRegular;
    letter-spacing: 0.05rem;
    margin-bottom: 1rem;
    min-width: 228px;
    height: 3.5rem;
    float: left;
    margin-top: 2rem;
    padding: 0 22px;
    overflow: visible;
    font-size: 12px;
    color: #fff;
    background-color: #02291a;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 10px;
    transition: all 0.3s;

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
  }
`;
