// import tela1 from "../../../images/ilustration/Tela-1.png";
// import tela2 from "../../../assets/grafico.json";
import tela3 from "../../../images/ilustration/Tela-0.png";

import { BiCurrentLocation } from "react-icons/bi";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";

// const graficoOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: tela2,
//   renderSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };

export const SliderDataMobile = [
  {
    div: (
      <div className="content">
        {/* <div className="divIllustration">
          <img src={tela1} alt="Imagem" />
        </div> */}
        <div className="divTextButton">
          <h1>Rastreie a frota da sua empresa!</h1>
          <p>
            <BiCurrentLocation
              color="#007b4b"
              font-size="10" width="80%"
              style={{ marginRight: "0.5rem" }}
            />
            Saiba mais do que a localização dos seus veículos! Temos ferramentas
            que vão te auxiliar com a gestão e economia da logística de sua
            empresa
          </p>
          <div className="buttons">
            <Link to="/orcamentoecortedecustos" className="botaolink">
              Calcule agora a economia que você terá
            </Link>
            <a
              href="http://wa.me/553535219531?text=Ol%C3%A1,%20tudo%20bem?%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento%20com%20a%20RFinds%20-%20%20%20Rastreamentos."
              target="_blank"
              className="botaolink2"
            >
              Solicite já o seu orçamento
            </a>
          </div>
        </div>
      </div>
    ),
  },
  {
    div: (
      <div className="content">
        {/* <div className="divIllustration">
          <Lottie
            options={graficoOptions}
            isClickToPauseDisabled
            height={240}
          ></Lottie>
        </div> */}
        <div className="divTextButton">
          <div className="areaTextMobile">
            <h1>Números que fazem a diferença!</h1>
            <p>
              <BiCurrentLocation
                color="#007b4b"
                font-size="10"
                style={{ marginRight: "0.5rem" }}
              />
              11% de economia com as rotas traçadas pelo RFinds
            </p>
            <p>
              <BiCurrentLocation
                color="#007b4b"
                font-size="10"
                style={{ marginRight: "0.5rem" }}
              />
              42% de aumento na produtividade dos motoristas
            </p>
            <p>
              <BiCurrentLocation
                color="#007b4b"
                font-size="10"
                style={{ marginRight: "0.5rem" }}
              />
              32% de economia em combustível
            </p>
            <p>
              <BiCurrentLocation
                color="#007b4b"
                font-size="10"
                style={{ marginRight: "0.5rem" }}
              />
              Entre outras ferramentas de gestão e logística
            </p>
          </div>
          <div className="buttons">
            <Link to="/orcamentoecortedecustos" className="botaolink">
              Calcule agora a economia que você terá
            </Link>
            <a
              href="http://wa.me/553535219531?text=Ol%C3%A1,%20tudo%20bem?%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento%20com%20a%20RFinds%20-%20%20%20Rastreamentos."
              target="_blank"
              className="botaolink2"
            >
              Solicite já o seu orçamento
            </a>
          </div>
        </div>
      </div>
    ),
  },
  {
    div: (
      <div className="content">
        <div className="divIllustration">
          <img src={tela3} alt="Imagem" />
        </div>
        <div className="divTextButton">
          <div className="textContent">
            <h1>Rastreie a frota da sua empresa!</h1>
            <p>
              <BiCurrentLocation
                color="#007b4b"
                font-size="10"
                style={{ marginRight: "0.5rem" }}
              />
              Saiba mais do que a localização dos seus veículos! Temos ferramentas
              que vão te auxiliar com a gestão e economia da logística de sua
              empresa
            </p>
          </div>

          <div className="buttons">
            <Link to="/orcamentoecortedecustos" className="botaolink">
              Calcule agora a economia que você terá
            </Link>
            <a
              href="http://wa.me/553535219531?text=Ol%C3%A1,%20tudo%20bem?%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento%20com%20a%20RFinds%20-%20%20%20Rastreamentos."
              target="_blank"
              className="botaolink2"
            >
              Solicite já o seu orçamento
            </a>
          </div>
        </div>
      </div>
    ),
  },
];
