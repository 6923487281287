import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";

export const InformationsHotBar = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #02291a;
  .slideContent {
    margin: 2rem 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #ffff;
    text-transform: uppercase;
  }

  .slideContent span {
    font-family: TommyBold;
    letter-spacing: 0.05rem;
    text-shadow: black 1px 1px 1px;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .slideContent p {
    font-family: TommyLight;
    letter-spacing: 0.05rem;
    text-shadow: black 1px 1px 1px;
    margin: 0;
    font-size: 1rem;
    line-height: normal;
  }

  @media (min-width: 925px) {
    display: none;
  }
`;
