import React from "react";
import { InformationsHotBar } from "./styles";
import Lottie from "react-lottie";
import { LottieData } from "./LottieData";

// Import Swiper React components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import "swiper/components/effect-cube/effect-cube.scss";

const InformationsBarMobile = () => {
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
  return (
    <>
      <InformationsHotBar>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          loop
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <div className="slideContent">
              <Lottie
                options={LottieData[0].options}
                isClickToPauseDisabled
                height={110}
              />
              <span>98%</span>
              <p>
                Clientes <br />
                satisfeitos
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slideContent">
              <Lottie
                options={LottieData[1].options}
                isClickToPauseDisabled
                height={110}
              />
              <span>437</span>
              <p>
                Veiculos <br />
                recuperados
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slideContent">
              <Lottie
                options={LottieData[2].options}
                isClickToPauseDisabled
                height={110}
              />
              <span>25.768</span>
              <p>
                Clientes <br />
                ativos
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slideContent">
              <Lottie
                options={LottieData[3].options}
                isClickToPauseDisabled
                height={110}
              />
              <span>247</span>
              <p>
                Empresas <br />
                parceiras
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </InformationsHotBar>
    </>
  );
};

export default InformationsBarMobile;
