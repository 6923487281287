import React, { Component, useState } from "react";
import {
  Content,
  ContentHeader,
  ContentReducao,
  IndiceCalculos,
  InputVeiculo,
  Results,
} from "./styles";

import Lottie from "react-lottie";
import { LottieData } from "./LottieData";

const Orcamento = () => {
  const [mensal, setMensal] = useState("");
  const [anual, setAnual] = useState("");
  const [payback, setPayBack] = useState("");

  class PostForm extends Component {
    constructor(props) {
      super(props);

      this.state = {
        selectVeicleType: "",
        km: "",
        qtVeiculos: "",
        precCombustivel: "",
      };
    }

    calCaminhao = () => {
      const { km, qtVeiculos, precCombustivel } = this.state;
      const Mensal = ((km / 3) * 0.06 * qtVeiculos * precCombustivel).toFixed(
        2
      );
      const Anual = (Mensal * 12).toFixed(2);
      const payBack = Math.ceil((204 * qtVeiculos - 12 * qtVeiculos) / Mensal);
      setMensal(Mensal);
      setAnual(Anual);
      setPayBack(payBack);
    };

    calTratorOuMaquinas = () => {
      const { km, qtVeiculos, precCombustivel } = this.state;
      const Mensal = ((km / 2) * 0.05 * qtVeiculos * precCombustivel).toFixed(
        2
      );
      const Anual = (Mensal * 12).toFixed(2);
      const payBack = Math.ceil((204 * qtVeiculos - 12 * qtVeiculos) / Mensal);
      setMensal(Mensal);
      setAnual(Anual);
      setPayBack(payBack);
    };

    calMista = () => {
      const { km, qtVeiculos, precCombustivel } = this.state;
      const Mensal = ((km / 6) * 0.05 * qtVeiculos * precCombustivel).toFixed(
        2
      );
      const Anual = (Mensal * 12).toFixed(2);
      const payBack = Math.ceil((204 * qtVeiculos - 12 * qtVeiculos) / Mensal);
      setMensal(Mensal);
      setAnual(Anual);
      setPayBack(payBack);
    };

    calCarro = () => {
      const { km, qtVeiculos, precCombustivel } = this.state;
      const Mensal = ((km / 9) * 0.04 * qtVeiculos * precCombustivel).toFixed(
        2
      );
      const Anual = (Mensal * 12).toFixed(2);
      const payBack = Math.ceil((204 * qtVeiculos - 12 * qtVeiculos) / Mensal);
      setMensal(Mensal);
      setAnual(Anual);
      setPayBack(payBack);
    };

    calMoto = () => {
      const { km, qtVeiculos, precCombustivel } = this.state;
      const Mensal = ((km / 20) * 0.03 * qtVeiculos * precCombustivel).toFixed(
        2
      );
      const Anual = (Mensal * 12).toFixed(2);
      const payBack = Math.ceil((204 * qtVeiculos - 12 * qtVeiculos) / Mensal);
      setMensal(Mensal);
      setAnual(Anual);
      setPayBack(payBack);
    };

    changeHandler = (e) => {
      e.target.value.replace(",", ".");
      this.setState({ [e.target.name]: e.target.value });
    };

    submitHandler = (e) => {
      e.preventDefault();
      if (this.state.selectVeicleType == "tratorMaquina") {
        this.calTratorOuMaquinas();
      } else if (this.state.selectVeicleType == "caminhao") {
        this.calCaminhao();
      } else if (this.state.selectVeicleType == "mista") {
        this.calMista();
      } else if (this.state.selectVeicleType == "carro") {
        this.calCarro();
      } else if (this.state.selectVeicleType == "moto") {
        this.calMoto();
      }
    };

    render() {
      const { selectVeicleType, km, qtVeiculos, precCombustivel } = this.state;
      return (
        <>
          {/* <div className="lottie-whatsapp">
            <a href="http://wa.me/553535219531?text=Ol%C3%A1,%20tudo%20bem?%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento%20com%20a%20RFinds%20-%20%20%20Rastreamentos.">
              <Lottie
                options={LottieData[0].options}
                isClickToPauseDisabled
                height={50}
                width={50}
              />
            </a>
          </div> */}
          <InputVeiculo>
            <select
              name="selectVeicleType"
              id="veiculos"
              value={selectVeicleType}
              onChange={this.changeHandler}
            >
              <option value="ecolher">Escolher...</option>
              <option value="tratorMaquina">Trator ou Máquinas</option>
              <option value="caminhao">Caminhão</option>
              <option value="mista">Mista</option>
              <option value="carro">Carro</option>
              <option value="moto">Moto</option>
            </select>
          </InputVeiculo>

          <form onSubmit={this.submitHandler}>
            <ul>
              <li>
                <h5>Km Rodado / Mês por Veículo</h5>
                <input
                  name="km"
                  value={km}
                  type="number"
                  placeholder="Km"
                  onChange={this.changeHandler}
                ></input>
              </li>
              <li id="meio">
                <h5>Quantidade de Veículo</h5>
                <input
                  name="qtVeiculos"
                  value={qtVeiculos}
                  type="number"
                  placeholder="Veículos"
                  lang="ar-EG"
                  onChange={this.changeHandler}
                ></input>
              </li>
              <li>
                <h5>Preço por Litro de Combustível</h5>
                <input
                  name="precCombustivel"
                  value={precCombustivel}
                  type="number"
                  placeholder="R$"
                  lang="ar-EG"
                  onChange={this.changeHandler}
                ></input>
              </li>
            </ul>

            <button type="submit" className="enviar">
              Enviar
            </button>
          </form>
        </>
      );
    }
  }

  return (
    <Content>
      <ContentReducao>
        <h2>Veja o quanto você economizará com o Rfinds Empresarial</h2>
        <h3>Escolha o tipo de veículo</h3>
        <ContentHeader>
          <IndiceCalculos>
            <PostForm />
          </IndiceCalculos>
        </ContentHeader>
        <Results>
          <div id="h3div">
            <h3>Resultados</h3>
          </div>
          <div id="contentResults">
            <div className="FormResult">
              <div>
                <h5>
                  Economia Média <br></br> Mensal
                </h5>
              </div>
              <div>
                <p className="dados">R$ {mensal}</p>
              </div>
            </div>
            <div className="FormResult">
              <div>
                <h5>
                  Economia Média <br></br> Anual
                </h5>
              </div>
              <div>
                <p className="dados">R$ {anual}</p>
              </div>
            </div>
            <div className="FormResult">
              <div>
                <h5>
                  Retorno investimento <br></br> (Pay Back)
                </h5>
              </div>
              <div>
                <p className="dados">{payback} Meses</p>
              </div>
            </div>
          </div>
        </Results>
        <p>
          Os resultados são providos por meio de uma média feita na base de
          dados fornecida pelos nossos clientes!
        </p>
      </ContentReducao>
    </Content>
  );
};

export default Orcamento;
