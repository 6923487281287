import styled from "styled-components";

export const Content = styled.div`
  width: 100vw;
  height: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: center;

  @media (max-width: 925px) {
    display: none;
  }

  .lottie-whatsapp {
    position: fixed;
    bottom: 1rem;
    right: 0;
    margin-right: 0.5rem;
    z-index: 100;
    cursor: pointer;
  }
`;

export const ContentSlides = styled.div`
  display: flex;

  justify-content: center;
  width: 100%;
  height: 100%;
  .content {
    max-width: 1170px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
  }

  .content .divTextButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 55%;
    color: rgb(2 64 40);
    h1 {
      font-family: TommyBold;
      font-size: 2.5rem;
    }
    p {
      margin-top: 5px;
      margin-bottom: 5px;
      color: black;
      background: transparent;
      font-size: 1.0rem;
      font-weight: bold;
    }
    p1 {
      margin-top: 5px;
      margin-bottom: 5px;
      color: black;
      background: transparent;
      font-size: 1.0rem;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
      font-family: TommyBold;
      letter-spacing: 0.05rem;
    }

    .botaolink {
      min-width: 228px;
      width: 64%;
      height: 3rem;
      float: left;
      margin-top: 2rem;
      padding: 0 22px;
      overflow: visible;
      font-size: 0.61rem;
      display: flex;
      align-items: center;
      text-align: center;
      color: #fff;
      background-color: #041334;
      font-weight: 200;
      text-transform: uppercase;
      border-radius: 50px;
      transition: all 0.3s;
      text-align: center;

      text-decoration: none;
      margin-right: 1rem;
      :hover {
        cursor: pointer;
        transform: scale(1.08);
        box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.6);
        fill: rgb(2 64 40);
        transition: 200ms ease-in-out;
      }
    }

    .botaolink2 {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 228px;
      width: 64%;
      height: 3rem;
      float: left;
      margin-top: 0.8rem;
      padding: 0 22px;
      overflow: visible;
      font-size: 0.61rem;
      color: #fff;
      background-color: #02291a;
      font-weight: 200;
      text-transform: uppercase;
      border-radius: 50px;
      transition: all 0.3s;

      text-decoration: none;
      :hover {
        cursor: pointer;
        transform: scale(1.08);
        box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.6);
        fill: rgb(2 64 40);
        transition: 200ms ease-in-out;
      }
    }
  }

  .content .divIllustration {
    max-width: 35%;
    max-height: 36rem;

    img {
      height: 706px;
      width: 210%;
      margin-top: 36px;
    }
  }
`;
