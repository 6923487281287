import React from "react";
import { Coments, TextoAvaliacao, TextoDepoimentos } from "./styles";
import { FeedBackData } from "./FeedBackData";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

const Feedback = () => {
  return (
    <Coments id="AvaliacoesMobile">
      <TextoAvaliacao>
        <h3>Nossos Clientes</h3>
      </TextoAvaliacao>
      <TextoDepoimentos>
        Confira os depoimentos de quem usa e aprova as soluções R-Finds!
      </TextoDepoimentos>
      <div className="ContentSlideComents">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {FeedBackData.map((conteudo) => {
            return <SwiperSlide>{conteudo.div}</SwiperSlide>;
          })}
        </Swiper>
      </div>
    </Coments>
  );
};

export default Feedback;
