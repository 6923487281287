import React from "react";
import {
  Content,
  DivDireita,
  DivTexto2,
} from "./styles";
import carros from "../../../images/ImagensSelect/carros.png";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import logored from "../../../images/ImagensSelect/logored.png";
import { Ri24HoursFill} from "react-icons/ri";
// import notebook from "../../../images/ImagensSelect/notebook.jpg";
import { Link } from "react-router-dom";
import fundo from "../../../images/ImagensSelect/fundo.jpg";
import rast from "../../../images/ImagensSelect/ras.png";


const Select = () => {
  return (
    <Content>
      <DivDireita>
        <DivTexto2>
      
        <div className="menu">
            <div className="logo">
              <img src={logored} alt="topo"></img>
            </div>
            <div className="tel">
              <Ri24HoursFill color="4c4c4c" size="18" className="a"/>
              <p> 35 3521-9531</p>
              
            </div>
        </div>

        <div className="foto">

          <div className="text">
            <p className="par">
              Tecnologia em <b>rastreamento</b><br></br> de veículos e frotas!
            </p>
          </div>

          <div className="rastreamento">
            <img src={rast} className="rast"></img>
          </div>

          <div className="cars">
            <img src={carros} className="Carros"></img>
          </div>
        </div>

        <div className="textos">
            <div className="caixapessoal">
              <a id="RfindsEmpresarial" href="/empresa">
                <h1>Rfinds Empresarial</h1>
                <p>
                Tenha uma visão rápida e completa<br></br>da situação de cada veículo da sua frota<br></br>
                com apenas um clique!
                </p>
              </a>
          </div>


          <div className="caixaempresa">
            <a id="RfindsPessoal" href="https://rfinds.com.br/pessoal/home">
              <h1>Rfinds Pessoal</h1>
              <p>
              Rápido e prático! Visualize em tempo<br></br>real detalhes de toda a movimentação<br></br>
                do seu veículo.
              </p>
            </a>
            </div>
          </div>
        </DivTexto2>
        <img src={fundo} id="ImageFundo2"></img>
        <div className="Fundo"></div>

        <div className="redesSociais">
                <a href="https://www.facebook.com/rfinds/" target="_blank" className="fb">
                  <FaFacebookF size={25} color="#4c4c4c"/>
                </a>

                <a href="https://www.instagram.com/rfindsoficial/" target="_blank" className="insta">
                  <FaInstagram size={25} color="#4c4c4c"/>
                </a>

                <a
                href="https://www.linkedin.com/in/rfinds-rastreamento-abb3a31b3/"
                target="_blank"
                >
                  <FaLinkedinIn size={25} color="#4c4c4c"/>
                </a>
            </div>
      </DivDireita>
    </Content>
  );
};

export default Select;
