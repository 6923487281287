import React from "react";
import { Switch, Route } from "react-router-dom";
import Index from "./pages/Index";
import PageOrcamento from "./pages/PageOrcamento";
import PageSelect from "./pages/PageSelect";

export default function App() {
  return (
    <Switch>
      <Route path="/" exact component={PageSelect} />
      <Route path="/orcamentoecortedecustos" exact component={PageOrcamento} />
      <Route path="/empresa" exact component={Index}/>
    </Switch>
  );
}
