import { Link } from "react-router-dom";
import styled from "styled-components";

export const Fundo = styled.div`
  background-color: #101422;
  border-bottom: #416d51 1px solid;
  height: 61px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  border-bottom: #394057 1px solid;
  z-index: 0;

  @media (min-width: 1025px) {
    display: none;
  }
`;

export const SecoesBotoes = styled.div`
  display: flex;
  /* flex:1; */

  a {
    border-left: #394057 1px solid;
    width: 60px;
    height: 60px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BotaoMenuMobile = styled(Link)`
  border-left: #394057 1px solid;
  width: 60px;
  height: 60px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Menu = styled.div`
  display: flex;
`;

export const SideBarMenu = styled.div`
  position: fixed;
  top: 0;
  background: #101422;
  width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${({ aberto }) => (aberto ? "1" : "0")};
  left: ${({ aberto }) => (aberto ? "0" : "-20rem")};
  transition: all 1s;
  z-index: 3;

  img {
    margin-top: 2rem;
    margin-left: 0;
  }

  ul {
    margin-top: 2rem;
    width: 100%;
    margin-right: 2.5rem;
  }

  ul li {
    cursor: pointer;
    outline: none;
    padding: 15px;
    border-top: #007b4b 1px solid;

    color: white;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    font-weight: 500;

    transition: all 0.3s;

    :hover {
      color: rgb(0, 153, 51);
    }
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

export const Maskmenu = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  position: relative;
  outline: none;
  float: left;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  background: #000;
  opacity: ${({ aberto }) => (aberto ? "0.9" : "0")};
  display: ${({ aberto }) => (aberto ? "block" : "none")};
  transition: all 0.2s ease-in-out;
`;

export const DivHamburguer = styled.div`
  position: absolute;
  z-index: 3;
  transition: all 1s;
  left: ${({ aberto }) => (aberto ? "15rem" : "0")};
  display: flex;
  flex: auto;
  margin-top: 1.1rem;
  align-items: center;
  padding: 0 15px;
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const Hamburguer = styled.div`
  div {
    display: ${({ aberto }) => (aberto ? "flex" : "block")};
    width: 2rem;
    height: 1.5rem;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    cursor: pointer;

    span {
      width: 1.375rem;
      height: 2px;
      background: #fff !important;
      margin-top: 0;
      border-radius: 2px;
      box-shadow: 0.1rem 0.1rem 1px #000;

      float: left;
      padding: 0;
      border: none;
      outline: none;
      text-decoration: none;
      font-weight: 400;
      appearance: none;
      transition: all 200ms ease-in-out;

      margin-top: 0.25rem;
    }

    #l1 {
      transform: ${({ aberto }) => (aberto ? "rotate(45deg)" : "")};
      position: ${({ aberto }) => (aberto ? "absolute" : "")};
    }

    #l2 {
      opacity: ${({ aberto }) => (aberto ? "0" : "1")};
    }

    #l3 {
      transform: ${({ aberto }) => (aberto ? "rotate(-45deg)" : "")};
      position: ${({ aberto }) => (aberto ? "absolute" : "")};
    }
  }
`;

export const Logo = styled.img`
  width: 105px;
  height: 26px;
  margin-left: 3.5rem;
`;
