import styled from "styled-components";

export const Content = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #fff;
  @media (min-width: 925px) {
    display: none;
  }
`;

export const Page = styled.div`
  margin: 2rem 0;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  max-width: 1170px;
  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  .contentImg{
    width:100%;
    height: 70vh;
    position: relative;
  }

  h2 {
    display: flex;
    margin: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 2.3rem;
    color: #02291a;
    font-family: TommyBold;
    -webkit-letter-spacing: 0.05rem;
    -moz-letter-spacing: 0.05rem;
    -ms-letter-spacing: 0.05rem;
    letter-spacing: 0.05rem;
    :after {
      content: "";
      width: 35%;
      height: 2px;
      float: left;
      margin-top: 20px;
      background: #1f3b7c;
      border-radius: 2.5px;
      left: 50%;
    }
    @media (max-width: 925px) {
      font-size: 1.6rem;
    }
  }
  



// GRID 1
.grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 25px;
  font-size: 31px;
  grid-column-gap: 11px;
  z-index: 10;
  position: absolute;
  top:4.2rem;
  left: 4.4rem;
  grid-row-gap: 75px;
  margin-top: 60px;
  font-weight: bold;
  color: #195a41;
}


.grid-row-1 .item1 {
  grid-row: 1;
  grid-column: 1;
}

.grid-row-1 .item2 {
	grid-row: 2;
  grid-column: 3;  
}

.grid-row-1 .item3 {
	grid-row: 4;
  grid-column: 1;  
}

.grid-row-1 .item4 {
	grid-row: 5;
  grid-column: 3;  
}
//FIM GRID 1



// GRID 2
.grade {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 20px;
  font-size: 0px;
  grid-column-gap: 0px;
  z-index: 10;
  position: absolute;
  top:-2.2rem;
  /* color: pink; */
  left: 0.5rem;
  grid-row-gap: 184px;
  margin-top: 60px;
}
/* .texto1{
  font-size: 10px;
} */


.grade .texto1 {
  grid-row: 2;
  font-size: px;
  grid-column: 1;
  width: 198px;
  max-width: 200px;
  max-height: 80px;
}

.grade .texto2 {
	grid-row: 1;
  grid-column: 2;  
  width: 247px;
  margin-top: -21px;
  margin-left: -132px;
}

.grade .texto3 {
	grid-row: 3;
  grid-column: 2; 
  width: 247px; 
  margin: -107px 0px 0px -123px;
}

.grade .texto4 {
	grid-row: 4;
  grid-column: 1;  
  width: 324px;
  margin-top: -98px;
}

h1{
  font-size: 13px;
  color: black;
  
}
p1{
  font-size: 11px;
  color: black;
}
// FIM GRID 2



  .predio {
    margin-top: 10px;
    height: 40rem;
    margin-left: -29px;
    /* width: 120%; */
    position: absolute;
  }

  h3 {
    padding-top: 0.25em;
    /* position: relative; */
    height: 0px;
    width: 1000px;
  }

  /* .vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
      0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important; */
  }

  /* h5 {
    margin-top: 0;
    color: rgba(0, 0, 0, 50%);
  } */

  .date {
    color: black;
    opacity: 75%;
  }

  #description {
    /* margin: 1.5em 0 2em 0; */
  }
`;

export const ContentInformations = styled.div`
  display: flex;
  align-items: center;

  margin-top: 2rem;
  margin-bottom: 1.2rem;
`;

export const HistText = styled.div`
  width: 100%;
  height: 100%;
  max-width: 585px;

  p {
    padding: 0 1.8rem;
    text-align: justify;
  }
`;

export const HistImage = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 585px;
  }
`;

export const Timeline = styled.div`
  margin-top: 2.5rem;
`;
