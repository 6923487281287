import React from "react";
import Menu from "../components/index/Menu/Menu";
import MenuTopo from "../components/index/MenuTopo/MenuTopo";
import MenuMobile from "../components/index/MenuMobile/MenuMobile";
import HomeSlide from "../components/index/HomeSlide/HomeSlide";
import HomeSlideMobile from "../components/index/HomeSlideMobile/HomeSlideMobile";
import InformationsBar from "../components/index/InformationsHotBar/InformationsBar";
import InformationsBarMobile from "../components/index/InformationsHotBarMobile/InformationsBarMobile";
import Formulario from "../components/index/Formulario/Formulario";
import Feedback from "../components/index/Feedback/Feedback";
import Footer from "../components/index/footer/footer";
import PointerRotate from "../components/index/PointerRotate/PointerRotate";
import AreaDownload from "../components/index/AreaDownload/AreaDownload";
import AreaVeja from "../components/index/AreaVeja/AreaVeja";
import FooterMobile from "../components/index/FooterMobile/footerMobile";
import FeedbackMobile from "../components/index/FeedbackMobile/Feedback";
import AreaVejaMobile from "../components/index/AreaVejaMobile/AreaVeja";
import PointerRotateMobile from "../components/index/PointerRotateMobile/PointerRotateMobile";
import AreaDownloadMobile from "../components/index/AreaDownloadMobile/AreaDownload";
import History from "../components/index/History/History";
import HistoryMobile from "../components/index/HistoryMobile/History";

import { SliderData } from "../components/index/HomeSlide/SliderData";
import { SliderDataMobile } from "../components/index/HomeSlideMobile/SliderDataMobile";

const Index = () => {
  return (
    <div>
      <MenuTopo />
      <Menu />
      <MenuMobile />
      <HomeSlide slides={SliderData} />
      <InformationsBar />
      <HomeSlideMobile slides={SliderDataMobile} />
      <InformationsBarMobile />
      <AreaVeja />
      <AreaVejaMobile />
      <PointerRotate />
      <PointerRotateMobile />
      <AreaDownload />
      <AreaDownloadMobile />
      <History />
      <HistoryMobile/>
      <Feedback />
      <FeedbackMobile />
      <Formulario />
      <Footer />
      <FooterMobile />
    </div>
  );
};

export default Index;
