import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #f3f2f0;

  .lottie-whatsapp {
    position: fixed;
    bottom: 1rem;
    right: 0;
    margin-right: 0.5rem;
    z-index: 100;
    cursor: pointer;
  }
`;

export const ContentReducao = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 85%;
  background-color: #ddd;
  text-align: center;
  align-items: center;
  border-radius: 20px;
  margin: 2rem 0;
  box-shadow: 4px 4px 12px 0 rgb(0 0 0 / 45%);

  .dados {
    font-size: 2rem;
    font-weight: 500;
  }

  p {
    margin: 16px;
    font-style: italic;
  }

  h2 {
    text-shadow: 0.08rem 0.08rem black;
    color: #1f387c;
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  @media (max-width: 1085px) {
    margin: 1rem 0;
    .dados {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
`;

export const ContentHeader = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const InputVeiculo = styled.div`
  display: flex;

  align-items: center;
  width: 280px;
  min-height: 40px;
  height: 45px;
  overflow: hidden;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDI1NiAyNTYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPGc+CgkJPHBvbHlnb24gcG9pbnRzPSIyMjUuODEzLDQ4LjkwNyAxMjgsMTQ2LjcyIDMwLjE4Nyw0OC45MDcgMCw3OS4wOTMgMTI4LDIwNy4wOTMgMjU2LDc5LjA5MyAgICIgZmlsbD0iIzAwMDAwMCIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgc3R5bGU9IiI+PC9wb2x5Z29uPgoJPC9nPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjwvZz48L3N2Zz4=)
    no-repeat right #f3f2f0; /* novo ícone para o <select> */
  background-size: 5%;
  background-position-x: 255px;
  margin-right: 5px;
  border-radius: 10px;
  #veiculos {
    background: transparent; //importante para exibir o novo ícone
    width: 100%;
    padding: 5px;
    font-size: 16px;
    line-height: 1;
    border: 0;
    border-radius: 10px;
    height: 34px;
    -webkit-appearance: none;
  }
`;

export const IndiceCalculos = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1085px) {
      display: flex;
      flex-direction: column;
    }
  }

  ul li {
    display: flex;
    flex-direction: column;

    width: 280px;

    background-size: 7%;
    border-radius: 10px;
  }

  ul li input {
    background: white; //importante para exibir o novo ícone
    width: 96.5%;
    padding: 5px;
    font-size: 16px;
    line-height: 1;
    border: 0;
    border-radius: 10px;
    height: 34px;
    -webkit-appearance: none;
  }

  ul #meio {
    margin: 0 35px;
    @media (max-width: 1085px) {
      margin: 0;
    }
  }

  .enviar {
    cursor: pointer;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    border-radius: 2rem;
    background-color: #1f387c;
    text-transform: uppercase;
    height: 3.5rem;
    width: 30%;
    font-weight: bold;
    color: white;

    :hover {
      box-shadow: none;
      opacity: 0.95;
    }
  }
`;

export const Results = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
  background-color: rgb(0 123 75 / 20%);
  box-shadow: 4px 4px 12px 0 rgb(0 0 0 / 25%);

  .FormResult {
    @media (max-width: 975px) {
      margin-bottom: 1rem;
      width: 70%;
    }
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 20px;
  }

  #contentResults {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
    @media (max-width: 975px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 1085px) {
    width: 95%;
  }
`;
