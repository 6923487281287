import styled from "styled-components";

export const InformationsHotBar = styled.div`
  margin-top: 1rem;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  background-color: #02291a;
  /* background-image: linear-gradient(#007b4b,#f3f2f0 ); */

  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 1170px;
    margin: 0;
    padding: 0;
    color: white;
    text-transform: uppercase;
    text-align: center;
  }
  ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 195px;
  }

  ul li .satisfacao {
    height: 85px;
  }
  ul li .furto {
    height: 100px;
  }
  ul li .maosdadas {
    height: 85px;
  }

  ul li span {
    text-shadow: black 1px 1px 1px;
    font-size: 2rem;
    font-weight: 700;
    font-family: TommyBold;
  }

  ul li p {
    text-shadow: black 1px 1px 1px;
    font-family: TommyLight;
    letter-spacing: 0.05rem;
    margin: 0;
    font-size: 1.1rem;
    line-height: normal;
  }

  @media (max-width: 925px) {
    display: none;
  }
`;
