import Menu from "../components/pageSelect/Menu/Menu";
import MenuTopo from "../components/pageSelect/MenuTopo/MenuTopo";
import Select from "../components/pageSelect/AreaSelect/select"
import SelectMobile from "../components/pageSelect/AreaSelectMobile/select"
const PageSelect = () => {
    return (
      <div>
         {/* <MenuTopo/> */}
          {/* <Menu/> */}
          <Select/>
          <SelectMobile/>
      </div>
    );
  };
  
  export default PageSelect;