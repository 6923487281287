import React from "react";

import { Ri24HoursFill, RiFacebookBoxLine } from "react-icons/ri";
import { FiYoutube, FiInstagram } from "react-icons/fi";
import { FaPhoneVolume } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

import { Container, Content, SubContainer } from "./styles.js";

const MenuTopo = () => {
  return (
    <Content>
      <Container>
        <SubContainer>
          <a
            href="https://www.facebook.com/rfinds/"
            target="_blank"
          >
            <RiFacebookBoxLine size="23" className="facebook" />
          </a>

          <a href="https://www.instagram.com/rfindsoficial/" target="_blank">
            <FiInstagram size="20" className="instagram" />
          </a>

          {/* <a
            href="https://www.youtube.com/channel/UCtO68r6CJYexihA0FOMA-5Q"
            target="_blank"
          >
            <FiYoutube size="22" className="youtube" />
          </a> */}
        </SubContainer>

        <SubContainer>
          <div className="linksInformations">
            <Ri24HoursFill color="white" size="18"/>
            <p>35 3521-9531</p>
          </div>
          <div className="linksInformations">
            {/* <FaPhoneVolume color="white" size="18" /> */}
            {/* <p>35 3115-1846</p> */}
          </div>
          <div className="linksInformations">
            {/* <p>35 3115-1843</p> */}
          </div>
          <div className="linksInformations">
            {/* <p>35 3115-1845</p> */}
          </div>

          <div className="linksInformations">
            {/* <MdEmail color="white" size="18" /> */}
            {/* <p>contato@rfinds.com.br</p> */}
          </div>
        </SubContainer>
      </Container>
    </Content>
  );
};

export default MenuTopo;
