import React from "react";
import Menu from "../components/PageOrcamento/Menu/Menu";
import MenuTopo from "../components/PageOrcamento/MenuTopo/MenuTopo";
import MenuMobile from "../components/PageOrcamento/MenuMobile/MenuMobile";
import Orcamento from "../components/PageOrcamento/Orcamento/Orcamento"
import Footer from "../components/PageOrcamento/footer/footer";
import FooterMobile from "../components/PageOrcamento/FooterMobile/footerMobile";

const PageOrcamento = () => {
  return (
    <div>
      <MenuTopo />
      <Menu />
      <MenuMobile />
      <Orcamento />
      <Footer />
      <FooterMobile />
    </div>
  );
};

export default PageOrcamento;
